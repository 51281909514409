import { Controller } from '@hotwired/stimulus'
import i18next from 'i18next'
import TomSelect from 'tom-select'
import { getPersons } from '../../services/contactsService'
import { setCheckboxReadonly } from '../../utils/htmlUtils'

// Connects to data-controller="project-contacts--form"
export default class extends Controller {
  static targets = [
    'selectPerson', 'contactId', 'contactFieldsOverlay', 'contactFields',
    'allowLogin', 'projectContactPermission',
  ]
  static values = { projectId: String }

  connect() {
    this.selectPersonsSetup()
    this.useContactFields()
    window.addEventListener('contactSelectRole', this.handleContactSelectRole.bind(this))

    this.showPricesPermission = this.projectContactPermissionTargets.filter(el => el.value == 'show_prices')[0]
    this.showPricesSaved = this.showPricesPermission.checked
    this.editContactsPermission = this.projectContactPermissionTargets.filter(el => el.value == 'edit_contacts')[0]
    this.editContactsSaved = this.editContactsPermission.checked
    this.createEventPermission = this.projectContactPermissionTargets.filter(el => el.value == 'create_event')[0]
    this.createEventSaved = this.createEventPermission.checked
    this.editProjectPermission = this.projectContactPermissionTargets.filter(el => el.value == 'edit_project')[0]
    this.editProjectSaved = this.editProjectPermission.checked
  }

  disconnect() {
    this.selectPersons.destroy()
    window.removeEventListener('contactSelectRole', this.handleContactSelectRole.bind(this))
  }

  selectPersonsSetup() {
    this.selectPersons = new TomSelect(this.selectPersonTarget, {
      plugins: ['remove_button'],
      placeholder: i18next.t('views.project_contacts.add_from_addressbook'),
      maxItems: 1,
      inputClass: 'form-control form-control-sm',
      valueField: 'value',
      labelField: 'text',
      // searchField: [],
      createFilter: null,
      load: async (search, callback) => {
        const data = await getPersons(this.projectIdValue, search)
        callback(data)
      },
      preload: true,
      onItemAdd: (value) => {
        this.contactIdTarget.value = value
        this.useAddressBook()
      },
      onItemRemove: () => {
        this.useContactFields()
      }
    })
  }

  useAddressBook() {
    this.contactFieldsTarget.disabled = true
    this.contactFieldsOverlayTarget.classList.remove('d-none')
  }

  useContactFields() {
    this.contactFieldsTarget.removeAttribute('disabled')
    this.contactFieldsOverlayTarget.classList.add('d-none')
  }

  changeAllowLogin() {
    if (!this.allowLoginTarget.checked) {
      setCheckboxReadonly(this.showPricesPermission, true, false)
      setCheckboxReadonly(this.createEventPermission, true, false)
      setCheckboxReadonly(this.editContactsPermission, true, false)
      setCheckboxReadonly(this.editProjectPermission, true, false)
    } else if (this.contactRole) {
      this.onContactSelectRole(this.contactRole)
    }
  }

  handleContactSelectRole(e) {
    this.contactRole = e.detail.role
    this.onContactSelectRole(this.contactRole)
  }

  onContactSelectRole(contactRole) {
    switch (contactRole) {
      case 'client':
        setCheckboxReadonly(this.allowLoginTarget, false, this.allowLoginTarget.checked)
        setCheckboxReadonly(this.showPricesPermission, false, this.showPricesSaved)
        setCheckboxReadonly(this.createEventPermission, false, this.createEventSaved)
        setCheckboxReadonly(this.editContactsPermission, true, false)
        setCheckboxReadonly(this.editProjectPermission, true, false)
        break;
      case 'worker':
        setCheckboxReadonly(this.allowLoginTarget, false, this.allowLoginTarget.checked)
        setCheckboxReadonly(this.showPricesPermission, false, this.showPricesSaved)
        setCheckboxReadonly(this.createEventPermission, false, this.createEventSaved)
        setCheckboxReadonly(this.editContactsPermission, false, this.editContactsSaved)
        setCheckboxReadonly(this.editProjectPermission, false, this.editProjectSaved)
        break;
      case 'admin':
        setCheckboxReadonly(this.allowLoginTarget, true, this.allowLoginTarget.checked)
        setCheckboxReadonly(this.showPricesPermission, true, true)
        setCheckboxReadonly(this.createEventPermission, true, true)
        setCheckboxReadonly(this.editContactsPermission, true, true)
        setCheckboxReadonly(this.editProjectPermission, true, true)
        break;
      case undefined:
        break;
      default:
        console.error('Unrecognized contact role', contactRole)
        break;
    }
  }
}
