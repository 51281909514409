import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--delete-asset"
export default class extends Controller {
  static targets = [ "destroyField" ]

  destroy() {
    this.element.classList.add('d-none')
    this.destroyFieldTarget.value = true
  }
}
