import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  connect() {
    const modalEl = document.getElementById('mainModal')
    window.modal = new Modal(modalEl)
    window.modal.scrollTop = () => document.querySelector('.modal-body').scrollTo({ top: 0, behavior: 'smooth' })
    window.modal.show()
    modalEl.addEventListener('hide.bs.modal', () => this.cleanModal())
  }

  disconnect() {
    window.modal.hide()
  }

  cleanModal() {
    document.getElementById('main_modal').innerHTML = ''
  }
}
