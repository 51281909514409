import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="utils--mark-as-destroy"
export default class extends Controller {
  static targets = ['destroyRef', 'opacityElement']

  connect() {
    this.updateUI()
  }

  destroyBtn() {
    this.toggleMarkForDestroy()
  }

  toggleMarkForDestroy() {
    this.destroyRefTarget.value = this.isMarkedForDestroy() ? 'false' : 'true'
    this.updateUI()
  }

  isMarkedForDestroy() {
    return this.destroyRefTarget.value === 'true'
  }

  updateUI() {
    if (this.isMarkedForDestroy()) {
      this.element.classList.add('d-none')
    }
  }
}
