// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// import '@hotwired/turbo-rails'

import './config/i18next.js'
import './controllers'
import './helpers/notificationHelper'

document.addEventListener('turbo:before-prefetch', (e) => {
  e.preventDefault();
});

document.addEventListener('turbo:frame-render', async (e) => {
  if (['main', '_top'].includes(e.target.id))
    history.pushState(history.state, '', await e.detail.fetchResponse.location)
})
window.addEventListener('popstate', () => Turbo.visit(document.location))

// document.addEventListener('turbo:frame-missing', function(event) {
//   event.preventDefault()
//   event.detail.visit(event.detail.response.url)
// })
// document.addEventListener('turbo:click', (event) => { console.log('turbo:click', event) });
// document.addEventListener('turbo:before-visit', (_event) => { console.log('turbo:before-visit', event) });
// document.addEventListener('turbo:visit', (event) => { console.log('turbo:visit', event) });
// document.addEventListener('turbo:submit-start', (event) => { console.log('turbo:submit-start', event) });
// document.addEventListener('turbo:before-fetch-request', (event) => {
//   console.log('turbo:before-fetch-request', event)
//   event.target.innerHTML = `<div class="spinner-border" role="status">
//   <span class="visually-hidden">Loading...</span>
// </div>` + event.target.innerHTML
// });
// document.addEventListener('turbo:before-fetch-response', (event) => { console.log('turbo:before-fetch-response', event) });
// document.addEventListener('turbo:submit-end', (event) => { console.log('turbo:submit-end', event) });
// document.addEventListener('turbo:before-cache', (event) => { console.log('turbo:before-cache', event) });
// document.addEventListener('turbo:before-render', (event) => { console.log('turbo:before-render', event) });
// document.addEventListener('turbo:before-stream-render', (event) => { console.log('turbo:before-stream-render', event) });
// document.addEventListener('turbo:render', (event) => { console.log('turbo:render', event) });
// document.addEventListener('turbo:load', (event) => { console.log('turbo:load', event) });
// document.addEventListener('turbo:frame-render', (event) => { console.log('turbo:frame-render', event) });
// document.addEventListener('turbo:frame-load', (event) => { console.log('turbo:frame-load', event) });
// document.addEventListener('turbo:fetch-request-error', (event) => { console.log('turbo:fetch-request-error', event) });

const loadingOverlay = document.getElementById('loading-overlay')
document.addEventListener('turbo:before-fetch-request', () => {
  if (loadingOverlay.classList.contains('d-none')) {
    loadingOverlay.classList.remove('d-none')
    setTimeout(() => loadingOverlay.classList.add('d-none'), 400)
  }
})
