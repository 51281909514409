import { Controller } from '@hotwired/stimulus'

const csrfParamInput = () => {
  const csrfParamInput = document.createElement('input');
  csrfParamInput.type = 'hidden';
  csrfParamInput.name = document.querySelector('meta[name=csrf-param]').content;
  csrfParamInput.value = document.querySelector('meta[name=csrf-token]').content;
  return csrfParamInput;
}

const methodInput = (method) => {
  const methodInput = document.createElement('input');
  methodInput.type = 'hidden';
  methodInput.name = '_method';
  methodInput.value = method || 'post';
  return methodInput;
}

// Connects to data-controller="components--prompt-submit"
export default class extends Controller {
  connect() {
    this.promptClickHandler = () => {
      const promptText = this.element.getAttribute('data-prompt')
      if (promptText) {
        const inputValue = prompt(promptText)

        if (inputValue !== null) {
          const form = document.createElement('form')
          form.method = 'POST'
          form.action = this.element.dataset.url.replace('__value__', encodeURIComponent(inputValue))
          form.appendChild(csrfParamInput());
          form.appendChild(methodInput(this.element.dataset.method))
          document.body.appendChild(form)
          form.submit()
        }
      }
    }

    this.element.addEventListener('click', this.promptClickHandler)
  }

  disconnect() {
    // Remove the event listener when the element is disconnected
    this.element.removeEventListener('click', this.promptClickHandler)
  }
}
