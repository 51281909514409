import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="site-task-measurements--index-single"
export default class extends Controller {
  static targets = ['draw', 'measurements', 'drawingFields', 'measurementsFields', 'isDrawing']

  connect() {
    if (this.isDrawingTarget.value === 'true') {
      this.setDrawingMode()
    } else {
      this.setMeasurementsMode()
    }
  }

  setDrawingMode() {
    this.isDrawingTarget.value = 'true'
    this.setActive(this.drawTarget)
    this.setInactive(this.measurementsTarget)
    this.drawingFieldsTargets.forEach(t => t.classList.remove('d-none'))
    this.measurementsFieldsTargets.forEach(t => t.classList.add('d-none'))
  }

  setMeasurementsMode() {
    this.isDrawingTarget.value = 'false'
    this.setInactive(this.drawTarget)
    this.setActive(this.measurementsTarget)
    this.drawingFieldsTargets.forEach(t => t.classList.add('d-none'))
    this.measurementsFieldsTargets.forEach(t => t.classList.remove('d-none'))
  }

  setActive(target) {
    target.classList.remove('btn-outline-secondary')
    target.classList.add('btn-primary', 'active')
  }
  setInactive(target) {
    target.classList.remove('btn-primary', 'active')
    target.classList.add('btn-outline-secondary')
  }
}
