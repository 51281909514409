import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { en, it } from 'flatpickr/dist/l10n'

const localeDateFormat = (locale, enableTime) => {
  return locale === 'it' ? (enableTime ? 'd/m/Y H:i' : 'd/m/Y') : (enableTime ? 'Y-m-d H:i' : 'Y-m-d')
}

window.flatpickrOptions = (enableTime = true) => ({
  locale: window.appData.locale,
  altInput: true,
  altFormat: localeDateFormat(window.appData.locale, enableTime),
  enableTime,
})

// Connects to data-controller="utils--form--datetime-picker"
export default class extends Controller {
  connect() {
    flatpickr.localize(window.appData.locale === 'it' ? it : en);
    const enableTime = this.element.dataset.enableTime === 'true'
    flatpickr(this.element, window.flatpickrOptions(enableTime))
  }
}
