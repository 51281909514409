import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--navbar"
export default class extends Controller {
  connect() {
    this.listenerFunctions = []

    Array.from(document.querySelectorAll('.dropdown-toggle')).forEach(dd => {
      const clickListener = function (_e) {
        const el = this.nextElementSibling
        el.style.display = el.style.display === 'block' ? 'none' : 'block'
      }

      dd.addEventListener('click', clickListener)
      this.listenerFunctions.push({ element: dd, listener: clickListener })
      return dd
    })
  }

  disconnect() {
    this.listenerFunctions.forEach(({ element, listener }) => element.removeEventListener('click', listener))
  }
}
