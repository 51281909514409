import { export_templates } from './export_templates/it'
import { draw } from './draw/it'
import { gantt } from './gantt/it'
import { manual_measurements } from './manual_measurements/it'
import { project_contacts } from './project_contacts/it'
import { sites } from './sites/it'

export const views = {
  ...export_templates,
  ...draw,
  ...gantt,
  ...manual_measurements,
  ...project_contacts,
  ...sites,
}
