import { Controller } from '@hotwired/stimulus'
import { Offcanvas } from 'bootstrap'

import { useTitle } from './mixins/useTitle'

// Connects to data-controller="site-tasks--form-details"
export default class extends Controller {
  static targets = ['summaryDisplay', 'title', 'quantity', 'rateUnit', 'rateValue',
    'siteTaskMeasurementId', 'quantityDisplay', 'manualMeasurementUrl']

  connect() {
    this.tariff = ''
    useTitle(this)
    this.updateTitle()
    this.updateFormDetailsHandler = this.updateFormDetails.bind(this)
    this.element.addEventListener('updateFormDetails', this.updateFormDetailsHandler)
  }

  disconnect() {
    this.element.removeEventListener('updateFormDetails', this.updateFormDetailsHandler)
  }

  updateFormDetails(event) {
    const payload = event.detail
    this.quantityTarget.value = payload.quantity
    this.rateUnitTarget.value = payload.rate_unit
    this.siteTaskMeasurementIdTarget.value = payload.site_task_measurement_id

    const url = new URL(this.manualMeasurementUrlTarget.href)
    url.searchParams.set('id', payload.site_task_measurement_id)
    this.manualMeasurementUrlTarget.href = url.toString()
    this.updateTitle()

    Offcanvas.getInstance(document.getElementById('offcanvasDetails'))?.hide()
  }
}
