import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="controllers--price-list--site-tasks--form"
export default class extends Controller {
  connect() {
    this.updateVariant()
  }

  updateVariant() {
    const hasVariants = this.element.querySelector('[data-form-estimate-variant]:not(.d-none)')
    if (hasVariants) {
      this.element.querySelectorAll('[data-hide-if-variants]').forEach(el => {
        el.classList.add('d-none')
      })
    } else {
      this.element.querySelectorAll('[data-hide-if-variants]').forEach(el => {
        el.classList.remove('d-none')
      })
    }
  }
}
