import { utils } from './utils/it'
import { views } from './views/it'

export const it = {
  translation: {
    attributes: {
      base: {
        no_results: 'Non ci sono risultati',
      },
      client_user: {
        title_placeholder: 'Seleziona...',
      },
      site: {
        revision_placeholder: 'Versione...',
      },
      task_category: {
        title_placeholder: 'Seleziona o aggiungi...',
        title_placeholder_full: 'Seleziona o aggiungi categoria...',
      },
      organization: {
        title_placeholder: `Seleziona una organizzazione...`,
      },
    },
    command: {
      add_selected: 'Aggiungi selezionati',
      remove: 'Rimuovi',
    },
    command_result: {
      error_occurred: "Si è verificato un errore. Riprova più tardi.",
      saved: "Salvato!",
    },
    enumerations: {
      contact_role: {
        admin: 'Amministratore',
        client: 'Cliente',
        worker: 'Collaboratore',
      },
    },
    helpers: {
      submit: {
        new: 'Aggiungi {{model}}',
      },
    },
    models: {
      agency: {
        one: "Agenzia",
        many: "Agenzie",
        other: "{{count}} Agenzie"
      },
      agency_user: {
        one: "Collaboratore",
        many: "Collaboratori",
        other: "{{count}} Collaboratori"
      },
      client_user: {
        one: "Cliente",
        many: "Clienti",
        other: "{{count}} Clienti"
      },
      export_template: {
        one: "Modello di esportazione",
        many: "Modelli di esportazione",
        other: "{{count}} Modelli di esportazione"
      },
      task_estimate: {
        one: "Tariffa",
        many: "Prezzario",
        other: "{{count}} Prezzario"
      },
      estimate_variant: {
        one: "Variante",
        many: "Varianti",
        other: "{{count}} Varianti",
      },
      message: {
        one: "Messaggio",
        many: "Messaggi",
        other: "{{count}} Messaggi"
      },
      room: {
        one: "Stanza",
        many: "Stanze",
        other: "{{count}} Stanze"
      },
      site: {
        one: "Cantiere",
        many: "Cantieri",
        other: "{{count}} Cantieri"
      },
      site_photo: {
        one: "Foto",
        many: "Foto",
        other: "{{count}} Foto"
      },
      site_task: {
        zero: "Nessuna lavorazione",
        one: "Lavorazione",
        many: "Lavorazioni",
        other: "{{count}} Lavorazioni"
      },
      site_task_variant: {
        one: "Variante",
        many: "Varianti",
        other: "{{count}} Varianti"
      },
      task_category: {
        one: "Categoria",
        many: "Categorie",
        other: "{{count}} Categorie"
      },
      organization: {
        one: "Impresa",
        many: "Imprese",
        other: "{{count}} imprese"
      },
    },
    site: {
      create_copy: 'Crea copia',
    },
    suppliers: {
      search_from: 'Seleziona...',
    },
    task_estimates: {
      search_from: 'Cerca lavorazione...',
    },
    utils,
    views,
  },
}
