import { utils } from './utils/en'
import { views } from './views/en'

export const en = {
  translation: {
    attributes: {
      base: {
        no_results: 'No results',
      },
      client_user: {
        title_placeholder: 'Select...',
      },
      site: {
        revision_placeholder: 'Version...',
      },
      task_category: {
        title_placeholder: 'Select or create new...',
        title_placeholder_full: 'Select or create new category...',
      },
      organization: {
        title_placeholder: `Select an organization...`,
      },
    },
    command: {
      add_selected: 'Add selected',
      remove: 'Remove',
    },
    command_result: {
      error_occurred: "An error occurred. Please try again later.",
      saved: "Saved!",
    },
    enumerations: {
      contact_role: {
        admin: 'Administrator',
        client: 'Client',
        worker: 'Coworker',
      },
    },
    helpers: {
      submit: {
        new: 'New {{model}}',
      },
    },
    models: {
      agency: {
        one: "Agency",
        many: "Agencies",
        other: "{{count}} Agencies",
      },
      agency_user: {
        one: "Collaborator",
        many: "Collaborators",
        other: "{{count}} Collaborators",
      },
      client_user: {
        one: "Client",
        many: "Clients",
        other: "{{count}} Clients",
      },
      export_template: {
        one: "Export Model",
        many: "Export Models",
        other: "{{count}} Export Models",
      },
      task_estimate: {
        one: "Rate",
        many: "Price List",
        other: "{{count}} Price Lists",
      },
      estimate_variant: {
        one: "Variant",
        many: "Variants",
        other: "{{count}} Variants",
      },
      message: {
        one: "Message",
        many: "Messages",
        other: "{{count}} Messages",
      },
      room: {
        one: "Room",
        many: "Rooms",
        other: "{{count}} Rooms",
      },
      site: {
        one: "Construction Site",
        many: "Construction Sites",
        other: "{{count}} Construction Sites",
      },
      site_photo: {
        one: "Photo",
        many: "Photos",
        other: "{{count}} Photos",
      },
      site_task: {
        zero: "No tasks",
        one: "Task",
        many: "Tasks",
        other: "{{count}} Tasks",
      },
      site_task_variant: {
        one: "Variant",
        many: "Variants",
        other: "{{count}} Variants",
      },
      task_category: {
        one: "Category",
        many: "Categories",
        other: "{{count}} Categories",
      },
      organization: {
        one: "Company",
        many: "Companies",
        other: "{{count}} Companies",
      },
    },
    site: {
      create_copy: 'Create copy',
    },
    suppliers: {
      search_from: 'Select...',
    },
    task_estimates: {
      search_from: 'Search task...',
    },
    utils,
    views,
  }
}
