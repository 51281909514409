export const prependElement = (wrapperNode, elementId, htmlContent) => {
  if (document.getElementById(elementId)) {
    document.getElementById(elementId).innerHTML = htmlContent;
  } else {
    const newElement = document.createElement('div');
    newElement.id = elementId;
    newElement.innerHTML = htmlContent;
    wrapperNode.prepend(newElement);
  }
}

export const setCheckboxReadonly = (element, readonly, value) => {
  if (readonly) {
    element.closest('.form-check').classList.add('opacity-25')
    element.setAttribute('readonly', 'readonly')
  } else {
    element.closest('.form-check').classList.remove('opacity-25')
    element.removeAttribute('readonly')
  }
  if (value === true || value === false) {
    element.checked = value
  }
}
