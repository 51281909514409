import { Controller } from '@hotwired/stimulus'
import Huebee from 'huebee/dist/huebee.pkgd.min'

// Connects to data-controller="utils--form--color-picker"
export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.tester = this.element.querySelector('label')
    const element = this.element.querySelector('input')
    const hueb = new Huebee(element, {
      notation: 'hex',
      shades: 7,
      saturations: 3,
    });
    element.disabled = false;

    hueb.on( 'change', (color, _hue, _sat, _lum) => {
      this.tester.style.color = color;
    })
  }
}
