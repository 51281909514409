
const fillFormField = (formFields, jsonValue) => {
  if (formFields.length == 1) {
    const formField = formFields[0];
    switch (formFields.type) {
      case 'checkbox':
        throw new Error(`fillFormField FixMe! Checkbox:`, formFields, jsonValue);
      case 'radio':
        throw new Error(`fillFormField FixMe! Radio:`, formFields, jsonValue);
      case 'select':
        throw new Error(`fillFormField FixMe! Select:`, formFields, jsonValue);
    }
    if (jsonValue !== undefined) {
      formField.value = jsonValue;
    }
    return;
  }
  throw new Error(`fillFormField FixMe! Found ${formFields.length}:`, formFields, jsonValue);
}

export const fillFormFromModel = (model, parent) => {
  for (const name in model) {
    if (Object.hasOwnProperty.call(model, name)) {
      const value = model[name];
      const fieldName = parent ? `${parent}[${name}]` : name;
      if ((value)?.constructor?.name == 'Object') {
        fillFormFromModel(value, fieldName);
      } else {
        if (document.getElementsByName(fieldName).length !== 0) {
          fillFormField(document.getElementsByName(fieldName), value);
        } else {
          // debugger; // apparently `site_task[icon][name]` returns an empty array
        }
      }
    }
  }
}
