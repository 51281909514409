import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="utils--form--dynamic-fields"
export default class extends Controller {
  static targets = ['template']
  static values = {
    uniqueId: String
  }

  add(event) {
    event.preventDefault()
    event.currentTarget.insertAdjacentHTML(
      'beforebegin',
      this.templateTarget.innerHTML.replace(
        new RegExp(`__CHILD_INDEX__${this.uniqueIdValue}`, 'g'),
        new Date().getTime().toString()
      )
    )
  }
}
