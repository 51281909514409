import i18next from 'i18next'
import { it } from './locales/it'
import { en } from './locales/en'

i18next.init({
  lng: window.appData.locale, // if you're using a language detector, do not define the lng option
  debug: window.appData.env == 'development',
  resources: {
    it,
    en,
  }
})
