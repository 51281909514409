import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="navigation--removals"
export default class extends Controller {
  connect() {
  }
  remove() {
    this.element.remove()
  }
}
