import i18next from 'i18next'
import { Controller } from '@hotwired/stimulus'

import { debounce } from '../../utils/timeUtils'
import { TirinnovoDrawing } from './lib/tirinnovo_drawing'
import { ApiService } from '../../services/apiService'

// Connects to data-controller="draw--form"
export default class extends Controller {
  static values = {
    siteId: String,
  }
  static targets = ['title', 'referenceValue', 'total']

  connect() {
    this.blueprint = {
      file: null,
      title: this.titleTarget.value,
      reference_line: [{ x: 100, y: 100 }, { x: 200, y: 100 }],
      reference_value: this.referenceValueTarget.value,
    }
    this.drawer = null
    this.launchDrawingBoard()

    this.selected = {
      title: null,
      total: null,
    }
    this.debouncedUpdateRemoteBlueprint = debounce(this.updateRemoteBlueprint.bind(this), 100)
  }

  switchMode(e) {
    this.drawer.switchMode(e.target.dataset.mode)
  }

  updateBackgroundOpacityChange(e) {
    this.drawer.updateBackgroundOpacity(e.target.value)
  }

  updateReferenceValue(e) {
    const value = e.target.value
    this.blueprint.reference_value = value
    this.drawer.updateReferenceValue(value)
    this.debouncedUpdateRemoteBlueprint()
  }

  onUpdateReferencePoints(points) {
    this.blueprint.reference_points = points
    this.updateRemoteBlueprint()
  }

  onTotalChange({ total, totalSelected }) {
    const output = {
      totalValue: (total || 0).toFixed(2),
      totalLabel: i18next.t('views.draw.total'),
      totalTitle: i18next.t('views.draw.total_title'),
    }

    if ((totalSelected || 0) > 0) {
      output.totalValue = (totalSelected).toFixed(2)
      output.totalLabel = i18next.t('views.draw.total_selected')
      output.totalTitle = i18next.t('views.draw.total_selected_title')
    }
    this.selected = {
      title: output.totalTitle,
      total: output.totalValue,
    }
    this.totalTarget.innerHTML = `<span title="${output.totalTitle}" class="strong">${output.totalLabel}: </span>
    <span>${output.totalValue}</span>`
  }

  resetViewClick() {
    this.drawer.resetView()
  }

  async handleFile(event) {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("blueprint[file]", file)
    formData.append("blueprint[site_id]", this.siteIdValue)
    const blueprint = await ApiService.post(`/sites/${this.siteIdValue}/blueprints`, formData, {})
    this.blueprint = blueprint

    fetch(`/sites/${this.siteIdValue}/blueprints`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  // To know when `renderStreamMessage` has finished, we don't want to update the title too early
  titleTargetConnected() {
    if (this.blueprint) {
      this.setBlueprint(this.blueprint)
    }
  }

  async selectBlueprint(event) {
    const blueprint = JSON.parse(event.target.dataset.blueprint)
    this.setBlueprint(blueprint)
  }

  async setBlueprint(blueprint) {
    this.blueprint = blueprint
    this.titleTarget.innerHTML = blueprint.title
    this.referenceValueTarget.value = blueprint.reference_value
    const fileResponse = await fetch(blueprint.file_url)

    console.log('setBl', blueprint);

    this.drawer.loadBlueprint({
      file: await fileResponse.blob(),
      reference_points: blueprint.reference_points,
      reference_value: blueprint.reference_value,
    })
  }

  launchDrawingBoard() {
    this.drawer = new TirinnovoDrawing({
      drawType: 'area',
      blueprint: this.blueprint,
      onTotalChange: this.onTotalChange.bind(this),
      onUpdateReferencePoints: debounce(this.onUpdateReferencePoints.bind(this), 100),
    })
  }

  retrieveSelectedValues () {
    return this.selected
  }

  async updateRemoteBlueprint() {
    if (!this.blueprint.id) { return }
    const blueprint = await ApiService.patch(`/sites/${this.siteIdValue}/blueprints/${this.blueprint.id}`, this.blueprint)
  }
}
