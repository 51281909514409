import { Controller } from '@hotwired/stimulus'
import { rounded } from '../../utils/numericUtils'

// Connects to data-controller="utils--select-vat-tax"
export default class extends Controller {
  static targets = ['button', 'dialog', 'select', 'vatTaxDisplay']

  connect() {
    this.selectVatTax()
  }

  openDialog(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
  }

  cancel(event) {
    event.preventDefault()
    this.dialogTarget.close()
  }

  selectVatTax(event) {
    event?.preventDefault()
    const value = this.selectTarget.value
    this.vatTaxDisplayTarget.innerHTML = `${rounded(value)}% IVA`
    this.dialogTarget.close()
  }
}
