import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contacts--form"
export default class extends Controller {
  static targets = ['externalOrganizationOverlay', 'role']

  connect() {
    this.selectRole()
  }

  selectRole() {
    const role = this.roleTargets.map(role => role.checked ? role.value : null).filter(el => el)[0]

    if (role === 'worker') {
      this.externalOrganizationOverlayTarget.classList.add('d-none')
    } else {
      this.externalOrganizationOverlayTarget.classList.remove('d-none')
    }

    window.dispatchEvent(new CustomEvent('contactSelectRole', { detail: { role } } ))
  }
}
