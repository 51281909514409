import { Controller } from '@hotwired/stimulus'
import i18next from 'i18next'
import TomSelect from 'tom-select'

let categories = []

// Connects to data-controller="price-lists--form-categories"
export default class extends Controller {
  static targets = [
    'category',
  ]

  connect() {
    this.initCategorySelect()
  }

  initCategorySelect() {
    this.categorySelects = this.categoryTargets.map(el => this.createSelect(el))
  }

  createSelect(el) {
    const selectInstance = new TomSelect(
      el,
      {
        placeholder: i18next.t('attributes.task_category.title_placeholder_full'),
        selectOnTab: true,
        plugins: ['remove_button'],
        multiple: false,
        create: true,
        sortField: {
          field: 'text',
          direction: 'asc'
        },
        onItemAdd: (newElement) => {
          categories = Object.keys(selectInstance.options)
          this.categorySelects.forEach(select => {
            if (select !== newElement) {
              select.addOption({
                value: newElement,
                text: newElement,
              })
            }
          })
        },
      },
    )
    return selectInstance
  }

  addTaskEstimate(el) {
    const categoryRawElement = el?.srcElement?.previousElementSibling
      ?.querySelector('[data-price-lists--form-categories-target=category]')
    const selectInstance = this.createSelect(categoryRawElement)
    selectInstance.addOptions(categories.map(category => ({ value: category, text: category })))
    this.categorySelects.push(selectInstance)
  }
}
