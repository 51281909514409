import { Controller } from '@hotwired/stimulus'
import i18next from 'i18next'
import TomSelect from 'tom-select'

// Connects to data-controller="agencies--estimates--form"
export default class extends Controller {
  static targets = ['selectSiteTaskTemplate', 'taskCategory', 'siteArea']

  connect() {
    this.initTaskCategorySelect()
  }

  initTaskCategorySelect() {
    this.taskCategorySelect = new TomSelect(
      this.taskCategoryTarget,
      {
        placeholder: i18next.t('attributes.task_category.title_placeholder'),
        selectOnTab: true,
        create: true,
        sortField: {
          direction: 'asc'
        },
        render: {
          option_create: (data, escape) => `<div class="create">
            <div class="ms-2 text-light btn btn-sm btn-primary" style="background: #0d6efd">
              Crea categoria <strong>${escape(data.input)}</strong>...
            </div>
          </div>`,
          no_results: (_data, _escape) => `<div class="no-results">${i18next.t('attributes.base.no_results')}</div>`,
        }
      },
    )
  }
}
