import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--confirm-action"
export default class extends Controller {
  static values = {
    message: String
  }

  confirm(event) {
    const confirmed = confirm(this.messageValue)
    if (!confirmed) {
      event.preventDefault()
    }
  }
}
