import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--select-on-focus"
export default class extends Controller {
  connect() {
    this.element.addEventListener('focus', this.selectOnFocus)
  }

  disconnect() {
    this.element.removeEventListener('focus', this.selectOnFocus)
  }

  selectOnFocus = () => {
    this.element.select()
  }
}
