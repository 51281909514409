import { Application } from '@hotwired/stimulus'
// import AddItemFormComponent from '../../components/add_item_form_component/add_item_form_component_controller'
// import ItemPillComponent from '../../components/item_pill_component/item_pill_component_controller'
// import AssociationInputComponent from '../../components/association_input_component/association_input_component_controller'

const application = Application.start()
// application.register('add-item-form-component', AddItemFormComponent)
// application.register('item-pill-component', ItemPillComponent)
// application.register('association-input-component', AssociationInputComponent)

export { application }
