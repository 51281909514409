import { Controller } from '@hotwired/stimulus'
import { Offcanvas } from 'bootstrap'

// Connects to data-controller="site-task-measurements--offcanvas"
export default class extends Controller {

  connect() {
    this.offCanvas = new Offcanvas(this.element)
    this.offCanvas.show()
    this.adjustOffCanvas()
  }

  adjustOffCanvas() {
    const offCanvasBackdrop = this.element.nextElementSibling
    if (offCanvasBackdrop) {
      offCanvasBackdrop.style.zIndex = '1060' // Below the offCanvas, same as modal
    }

    this.element.style.maxWidth = '500px' // Increase a bit the width
    this.element.style.width = '100%'
  }
}
