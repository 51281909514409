import { Controller } from '@hotwired/stimulus'
import consumer from '../../channels/consumer'
import { getRoomMessages } from '../../services/messagesService'
import { divider, serializeMessage } from './chatTemplates'

// Connects to data-controller="messages--form"
export default class extends Controller {
  static targets = ['messageLister', 'newMessage']
  static values = {
    projectId: String,
    roomId: String,
    siteId: String,
  }

  connect () {
    this.currentDate = new Date()
    this.prevDate = null

    if (this.roomIdValue) {
      this.connectToRoom()
    }

    this.messageListerTarget.addEventListener('newMessage', (e) => {
      const { message } = e.detail
      if (!this.roomIdValue) {
        this.roomIdValue = message.room_id
        this.connectToRoom()
      }

      this.newMessageTarget.value = ''
      this.newMessageTarget.focus()
    })
  }

  connectToRoom () {
    this.messagesChannel = consumer.subscriptions.create(
      {
        channel: 'MessagesChannel',
        room: this.roomIdValue,
      },
      {
        connected: async () => {
          const { data: messages } = await getRoomMessages(this.projectIdValue, this.roomIdValue, this.siteIdValue)
          this.messageListerTarget.innerHTML += this.addDividers(messages) // Modify this line
        },

        disconnected (e) {
          this.disconnect()
        },

        received: (message) => {
          this.messageListerTarget.innerHTML += this.addDividers([message])
          this.messageListerTarget.scroll({ top: this.messageListerTarget.scrollHeight + 500, behavior: 'smooth' })
        },
      },
    )
  }

  disconnect () {
    this.messagesChannel.unsubscribe()
  }

  addDividers (messages) {
    return messages.reduce((html, message) => {
      const messageDate = new Date(message.created_at).toDateString()
      if (messageDate !== this.prevDate) {
        html += `${divider(messageDate)}${serializeMessage(message)}`
        this.prevDate = messageDate
      } else {
        html += serializeMessage(message)
      }
      return html
    }, '')
  }
}
