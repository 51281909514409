import { messagesPage } from '../../locale'

export const divider = (date) => {
  const dateTitle = new Date(date).toLocaleString(undefined, { dateStyle: 'medium' })
  const title = date === new Date().toDateString() ? messagesPage.today : dateTitle
  return `<div class="divider d-flex align-items-center mb-4">
    <p class="text-center mx-3 mb-0" style="color: #a2aab7;">${title}</p>
  </div>`
}

export const serializeMessage = (message) =>
  message.user.id == window.appData.user.id ? messageMe(message) : messageOther(message)

const messageOther = (message) => (
  `<div class="d-flex flex-row justify-content-start">
    <div>
      <p class="small mb-1 fw-bold">${message.user.name}</p>
      <p class="small p-2 ms-3 mb-1 rounded-3 msg-other">${message.body}</p>
      <p class="small ms-3 mb-0 rounded-3 text-muted">${new Date(message.created_at).toLocaleString(undefined, { timeStyle: 'short' })}</p>
    </div>
  </div>`
)

const messageMe = (message) => (
  `<div class="d-flex flex-row justify-content-end mb-2 text-end">
    <div>
      <p class="small mb-1 fw-bold">${message.user.name}</p>
      <p class="small p-2 mb-1 text-white rounded-3 msg-me">${message.body}</p>
      <p class="small mb-0 rounded-3 text-muted d-flex justify-content-end">${new Date(message.created_at).toLocaleString(undefined, { timeStyle: 'short' })}</p>
    </div>
  </div>`
)
