import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import i18next from 'i18next'

import { getPersons } from '../../services/contactsService'

const badgeRoles = (escape, contact) => {
  let badges = (contact?.roles || []).map(role => {
    const cssClass = role === 'client' ? 'bg-info' : 'bg-secondary'
    return `<span class="ms-1 badge ${cssClass}">${escape(i18next.t(`enumerations.contact_role.${role}`))}</span>`
  }
  ).join(' ')
  return badges
}

// Connects to data-controller="events--form"
export default class extends Controller {
  static targets = ['selectPerson']
  static values = { projectId: String }

  connect() {
    this.selectPersonsSetup()
  }

  disconnect() {
    this.selectPersons.destroy();
  }

  selectPersonsSetup() {
    this.selectPersons = new TomSelect(this.selectPersonTarget, {
      plugins: ['remove_button'],
      preload: true,
      load: async (search, callback) => {
        const data = await getPersons(this.projectIdValue, search)
        callback(data)
      },
      // onItemAdd: () => {
      //   this.selectPersonTarget.closest('.form-group').querySelector('input[type="select-multiple"]')?.value = ''
      // },
      render: {
        item: (contact, escape) => `<div>${escape(contact.text)}${badgeRoles(escape, contact)}</div>`,
        option: (contact, escape) => {
          return `<div>${escape(contact.text)}${badgeRoles(escape, contact)}</div>`
        },
      },
    })
  }
}
