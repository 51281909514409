import { Controller } from '@hotwired/stimulus'
import i18next from 'i18next'
import TomSelect from 'tom-select'

import { lazyI18n } from '../../utils/lazyI18n'

// Connects to data-controller="site-task-measurements--site-draw"
export default class extends Controller {
  static values = {
    formId: String,
  }
  // `showInModalOnly` - if false will hide contained elements when page is opened in new tab
  static targets = ['name', 'isDrawing', 'showInModalOnly']

  connect() {
    this.drawingModalEl = document.getElementById('drawingModal')

    if (true) { // (this.drawingModalEl) {
      this.initNameSelect()
    } else {
      this.showInModalOnlyTarget.classList.add('d-none')
    }
  }

  disconnect() {
    window.modal?.hide()
    this.nameSelect?.destroy()
  }

  initNameSelect() {
    this.nameSelect = new TomSelect(
      this.nameTarget,
      {
        placeholder: lazyI18n('Nome'),
        selectOnTab: true,
        create: true,
        sortField: {
          direction: 'asc'
        },
        render: {
          option_create: (data, escape) => `<div class="create">
            <div class="ms-2 text-light btn btn-sm btn-primary" style="background: #0d6efd">
              ${i18next.t('command.add')}
            </div>
          </div>`,
          no_results: (_data, _escape) => ''
        }
      },
    )
  }

  insertTotal() {
    if (this.drawingModalEl) {
      const selected = this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-draw--form-container]'),
        'draw--form',
      ).retrieveSelectedValues()

      const totalEl = document.getElementById(`measure_total_${this.formIdValue}`)
      totalEl.value = selected.total
      totalEl.dispatchEvent(new Event('input', { bubbles: true }));

      document.getElementById('drawingModal').remove()
    }
  }
}
