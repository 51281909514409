import { fabric } from 'https://cdn.jsdelivr.net/npm/fabric@5.3.0/+esm'
import * as pdfjsLib from 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.min.mjs'
const PDF_JS_LIB = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.worker.min.mjs'

const Constants = {
  referenceLineColor: '#FFA700',
  strokeWidth: 2,
  handleSizeRadius: 5,
  defaultReferencePoints: [{ x: 100, y: 100 }, { x: 200, y: 100 }],
  defaultReferenceValue: 100,
}

const createRerenceLine = (reference_points) => (
  new fabric.Polyline(
    reference_points || Constants.defaultReferencePoints,
    {
      stroke: Constants.referenceLineColor,
      strokeWidth: 2,
      //fill: 'rgba(255,255,255,0.1)',
      strokeUniform: true,
      selectable: true,
      evented: true,
      // lockMovementX: true, // Prevent movement on the X-axis
      // lockMovementY: true, // Prevent movement on the Y-axis
      lockRotatyion: true,
      hasControls: true,
      hasBorders: false,
      perPixelTargetFind: true,
      type: 'referenceLine',
      padding: 10,
    }
  )
)

export class TirinnovoDrawing {
  /**
   * Creates an instance of Tirinnovo Canvas Drawing.
   *
   * @constructor
   * @param {Object} options - Option for configuring Tirinnovo Drawing component.
   * @param {string} [options.type='area'] - 'area' or 'line' (default to 'area')
   * @param {string} [options.canvas='#canvas'] - canvas selector (default to '#canvas')
   * @param {string} [options.toolCont='#toolCont'] - Tool container selector (default to '#toolCont')
   *
   * Tool container must contain the following HMTML elements with following class to work:
   *
   * UIdrawBtn (any)
   */
  constructor(options) {
    this.options = options
    this.UI = {}
    this.canvas = document.querySelector('#canvas')
    this.UI.toolCont = document.querySelector('#toolCont')
    let toolContainer = this.UI.toolCont
    this.UI.drawBtn = toolContainer.querySelector('.UIdrawBtn')
    this.drawType = options.drawType ?? 'area'
    fabric.Object.prototype.objectCaching = false
    this.isDragging = false
    this.lastPosX
    this.lastPosY
    this.gridLayerGroup
    this.activeShape = null
    this.pointArray = []
    this.lineArray = []
    this.isDrawing = false
    this.currentPath = null
    this.pointsForPath = []
    console.log('INIT CANVAS')

    this.canvas = new fabric.Canvas(this.canvas, {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight,
    })

    // LISTENERS
    this.UI.drawBtn.addEventListener('click', () => {
      if (this.isDrawing) {
        this.stopDrawing(this.pointArray)
      } else {
        this.toggleDrawPolygon()
      }
    })
    window.addEventListener('resize', this.resize)
    this.resize()
    console.log('isDrawing: ' + this.isDrawing)

    document.addEventListener('keydown', (event) => {
      if (['Delete', 'Backspace'].includes(event.key)) {
        if (this.isDrawing) {
          this.undoLastPoint()
        } else {
          this.deleteSelected()
        }
      }

      if (event.key === 'g') {
        console.log('refresh grid')
        this.drawGrid()
      }

      if (event.key === 'p') {
        this.getPathOfActiveObject()
      }

      if (event.key === 'Enter') {
        if (this.isDrawing) {
          this.stopDrawing(this.pointArray)
        }
      }
    })

    document.addEventListener('click', (event) => {
      if (event.target === this.canvas.upperCanvasEl) {
        // Clicked canvas
      } else if (event.target !== this.UI.drawBtn) {
        // Clicked outside canvas
        if (this.isDrawing) {
          this.stopDrawing(this.pointArray)
        }
      }
    })

    this.drawGrid()

    //--------------------
    this.canvas.on('mouse:down', this.handleMouseDown.bind(this))
    this.canvas.on('mouse:move', this.handleMouseMove.bind(this))
    this.canvas.on('mouse:up', this.handleMouseUp.bind(this))
    this.canvas.on('mouse:wheel', this.handleZoom.bind(this))
    this.canvas.on('after:render', this.fixStrokesSize.bind(this))
    //--------- reference line

    this.referenceLine = createRerenceLine(this.options.blueprint.reference_points)
    this.createCustomControl(this.referenceLine, false)
    this.canvas.add(this.referenceLine)
  }

  updateBackgroundOpacity(value) {
    if (this.canvas.backgroundImage) {
      this.canvas.backgroundImage.opacity = value / 100
      this.canvas.renderAll()
    }
  }

  loadBlueprint(blueprint) {
    if (blueprint.file?.type === 'application/pdf') {
      this.loadBackgroundPDF(blueprint.file)
    } else if (blueprint.file?.type.startsWith('image/')) {
      this.loadBackgroundImage(blueprint.file)
    } else {
      alert('File format non recognized')
      return
    }
    blueprint.reference_points = blueprint.reference_points || Constants.defaultReferencePoints
    blueprint.reference_value = blueprint.reference_value || Constants.defaultReferenceValue
    this.options.blueprint = blueprint

    this.canvas.remove(this.referenceLine)
    this.referenceLine = createRerenceLine(this.options.blueprint.reference_points)
    this.createCustomControl(this.referenceLine, false)
    this.canvas.add(this.referenceLine)
    console.log('blueprint', this.options.blueprint);

    // this.referenceLine.set({ points: blueprint.reference_points })

    this.resize()
    this.resetView()
    this.canvas.renderAll()
    this.calculateDimensions()
  }

  updateReferenceValue(value) {
    this.options.blueprint.reference_value = value
    this.calculateDimensions()
  }

  switchMode(mode) {
    console.log('Switching mode', mode)
    this.drawType = mode
  }

  fixStrokesSize() {
    let zoom = this.canvas.getZoom()
    let strokeWidth = Constants.strokeWidth / zoom
    this.canvas.forEachObject((obj) => {
      if (obj.type === 'poly' || obj.type === 'polyline') {
        obj.set('strokeWidth', strokeWidth) // Width should be same regardless the zoom level
      }
    })

    this.canvas.getObjects()
      .filter(obj => obj instanceof fabric.Circle)
      .forEach(obj => obj.set('radius', Constants.handleSizeRadius / zoom)) // Set handle radius to X pixels
    this.referenceLine.set('strokeWidth', strokeWidth * 2)
  }

  getReferenceLength() {
    const points = this.referenceLine.points
    const x1 = points[0].x
    const y1 = points[0].y
    const x2 = points[1].x
    const y2 = points[1].y
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
  }

  handleZoom(opt) {
    const delta = opt.e.deltaY
    let zoom = this.canvas.getZoom()
    zoom = zoom + delta / 1000
    if (zoom > 10) zoom = 10 // Limit maximum zoom level
    if (zoom < 0.1) zoom = 0.1 // Limit minimum zoom level
    this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
    opt.e.preventDefault()
    opt.e.stopPropagation()
    this.drawGrid() // Redraw grid after zooming
    this.fixStrokesSize()
  }

  drawGrid() {
    var vpt = this.canvas.viewportTransform
    var gridSize = 20 // Set the size of each grid cell
    var zoom = this.canvas.getZoom() // Get the current zoom level
    var me = this
    var canvas = me.canvas

    // Clear existing grid objects
    canvas.forEachObject((obj) => {
      if (obj.type === 'grid') {
        canvas.remove(obj)
      }
    })

    // Calculate the number of grid lines needed based on canvas size and a fixed grid size
    var numVerticalLines = Math.ceil(canvas.width / gridSize / zoom)
    var numHorizontalLines = Math.ceil(canvas.height / gridSize / zoom)
    var xoffst = vpt[4] / zoom
    var yoffst = vpt[5] / zoom
    var mxoffst = (xoffst % gridSize) - xoffst // modulo dell'offset x
    var myoffst = (yoffst % gridSize) - yoffst // modulo dell'offset y

    // Draw vertical grid lines
    for (var i = 0; i < numVerticalLines; i++) {
      var xpose = i * gridSize + mxoffst

      var line = new fabric.Line(
        [xpose, -yoffst, xpose, canvas.height / zoom - yoffst],
        {
          stroke: '#ccc',
          selectable: false,
          type: 'grid',
          strokeWidth: 0.3 / zoom,
        }
      )
      canvas.add(line)
      line.sendToBack()
    }

    // Draw horizontal grid lines
    for (var j = 0; j < numHorizontalLines; j++) {
      var xpose = j * gridSize + myoffst

      var line = new fabric.Line(
        [-xoffst, xpose, canvas.width / zoom - xoffst, xpose],
        {
          stroke: '#ccc',
          selectable: false,
          type: 'grid',
          strokeWidth: 0.3 / zoom,
        }
      )
      canvas.add(line)
      line.sendToBack()
    }
  }

  resize = () => {
    const parentElement = this.canvas.getElement().parentNode.parentNode

    // Calcola la larghezza includendo il padding
    const W =
      parentElement.clientWidth -
      parseInt(window.getComputedStyle(parentElement).paddingLeft) -
      parseInt(window.getComputedStyle(parentElement).paddingRight)

    // Calcola l'altezza includendo il padding
    const H =
      parentElement.clientHeight -
      parseInt(window.getComputedStyle(parentElement).paddingTop) -
      parseInt(window.getComputedStyle(parentElement).paddingBottom)

    this.canvas.setWidth(W)
    this.canvas.setHeight(H)
    this.drawGrid()
  }

  deleteSelected() {
    console.log('deleting selected object')
    let activeObjs = this.canvas.getActiveObjects()
    activeObjs.forEach((obj) => {
      if (obj.type != 'referenceLine') {
        this.canvas.remove(obj)
      }
    })
  }

  addPoint(options) {
    const evt = options.e
    const zoom = this.canvas.getZoom()
    const vpt = this.canvas.viewportTransform
    const X = evt.layerX / zoom - vpt[4] / zoom
    const Y = evt.layerY / zoom - vpt[5] / zoom
    const pointOption = {
      id: new Date().getTime(),
      radius: 5 / zoom,
      fill: '',
      stroke: '#ccc',
      strokeWidth: 1 / zoom,
      left: X,
      top: Y,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      objectCaching: false,
    }
    const point = new fabric.Circle(pointOption)
    if (this.pointArray.length === 0 && this.drawType == 'area') {
      point.set({ fill: 'red' }) // Make the first dot stand out
    }

    if (this.activeShape) {
      console.log('this.activeshape = true')
      let pos = this.canvas.getPointer(options.e)
      let points = this.activeShape.get('points')
      points.push({ x: X, y: Y })
      this.canvas.renderAll()
    } else {
      if (this.drawType == 'area') {
        let polyPoint = [{ x: X, y: Y }]
        let polygon = new fabric.Polygon(polyPoint, {
          stroke: '#ffffff',
          strokeWidth: 2 / zoom,
          fill: '#2222ff',
          opacity: 0.5,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        })
        this.activeShape = polygon
        this.canvas.add(polygon)
      }

      if (this.drawType == 'line') {
        const polyPoint = [{ x: X, y: Y }]
        const polyline = new fabric.Polyline(polyPoint, {
          stroke: '#2222ff',
          strokeWidth: 1,
          fill: '',
          opacity: 1,
          selectable: true,
          hasBorders: false,
          hasControls: true,
          evented: true,
          objectCaching: false,
          strokeLineCap: 'round',
          type: 'polyline',
          perPixelTargetFind: true,
        })
        this.activeShape = polyline
        this.canvas.add(polyline)
      }
    }
    this.pointArray.push(point)
    this.canvas.add(point)
  }

  updatePolylineBoundingBox(polyline) {
    // Ricalcola i punti della polyline
    polyline.setCoords()

    // Aggiorna il bounding box
    var aCoords = polyline.calcACoords()
    polyline.aCoords = aCoords

    // Aggiorna la canvas
    this.canvas.requestRenderAll()
  }

  addPath(points, options) {
    const pathData = this.generatePathData(points)
    const path = new fabric.Path(pathData, { ...options })
    this.canvas.add(path)
    path.set('strokeUniform', false)
  }

  getPathOfActiveObject() {
    const activeObject = this.canvas.getActiveObject()
    console.log(activeObject);
  }

  handleMouseDown(opt) {
    const evt = opt.e
    if (evt.altKey === true) {
      // pan del canvas se ALT key
      this.isDragging = true
      this.canvas.selection = false
      this.lastPosX = evt.clientX
      this.lastPosY = evt.clientY
    } else {
      if (this.isDrawing) {
        if (this.drawType == 'area') {
          if (
            this.pointArray[0] &&
            opt.target &&
            opt.target.id === this.pointArray[0].id
          ) {
            this.stopDrawing(this.pointArray)
          } else {
            this.addPoint(opt)
          }
        } else if (this.drawType == 'line') {
          this.addPoint(opt)
        }
        this.isDragging = false
      }
      // NON STA DISEGNANDO
      else {
        this.isDragging = true
        this.selection = false
        this.lastPosX = opt.e.clientX
        this.lastPosY = opt.e.clientY
      }
    }
  }

  stopDrawing(pointArray) {
    console.log(['stopDrawing() - isDrawing:', this.isDrawing])
    if (!this.isDrawing) return
    const points = []
    // collect points and remove them from the canvas
    for (const point of pointArray) {
      points.push({ x: point.left, y: point.top })
      this.canvas.remove(point)
    }

    if (this.drawType == 'area') {
      // remove lines from the canvas
      for (const line of this.lineArray) {
        this.canvas.remove(line)
      }

      // remove selected Shape and Line
      this.canvas.remove(this.activeShape)
      this.canvas.remove(this.activeLine)

      if (points.length >= 3) {
        // create a polygon from collected points
        const polygon = new fabric.Polygon(points, {
          id: new Date().getTime(),
          stroke: '#fff',
          opacity: 0.5,
          fill: '#55f',
          objectCaching: false,
          type: 'poly',
          perPixelTargetFind: true,
          strokeWidth: 1 / this.canvas.getZoom(),
        })
        polygon.lockMovementX = true
        polygon.lockMovementY = true
        this.canvas.add(polygon)
      }
    }
    if (this.drawType == 'line') {
      if (points.length >= 2) {
        this.createCustomControl(this.activeShape)
        this.fixselectionBound(this.activeShape)
      } else {
        this.canvas.remove(this.activeShape)
        this.activeShape = null
      }
    }
    this.isDragging = false
    this.toggleDrawPolygon()
  }
  fixselectionBound(fabricObject) {
    if (fabricObject) {
      var absolutePoint = fabric.util.transformPoint(
        {
          x: fabricObject.points[0].x - fabricObject.pathOffset.x,
          y: fabricObject.points[0].y - fabricObject.pathOffset.y,
        },
        fabricObject.calcTransformMatrix()
      )

      var newDim = fabricObject._setPositionDimensions({})
      var polygonBaseSize = fabricObject._getNonTransformedDimensions()
      var newX =
        (fabricObject.points[0].x - fabricObject.pathOffset.x) /
        polygonBaseSize.x
      var newY =
        (fabricObject.points[0].y - fabricObject.pathOffset.y) /
        polygonBaseSize.y
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)
      this.canvas.renderAll()
    }
  }
  handleMouseMove(opt) {
    var evt = opt.e
    this.curPointer = this.canvas.getPointer(opt.e)

    if (this.isDragging && evt.altKey === true) {
      var vpt = this.canvas.viewportTransform
      vpt[4] += evt.clientX - this.lastPosX
      vpt[5] += evt.clientY - this.lastPosY
      this.canvas.requestRenderAll()
      this.lastPosX = evt.clientX
      this.lastPosY = evt.clientY
      this.drawGrid()
      return
    }

    if (this.isDrawing) {
      if (this.activeLine && this.activeLine.class === 'line') {
        this.activeLine.set({
          x2: this.curPointer.x,
          y2: this.curPointer.y,
        })
        const points = this.activeShape.get('points')
        points[this.pointArray.length] = {
          x: this.curPointer.x,
          y: this.curPointer.y,
        }
        this.activeShape.set({
          points,
        })
      }
      this.canvas.renderAll()
    }
    this.calculateDimensions()

    var activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      // Ottieni i controlli dell'oggetto attivo
      var controls = activeObject.controls

      // Itera attraverso i controlli
      for (var controlId in controls) {
        if (controls.hasOwnProperty(controlId)) {
          var control = controls[controlId]

          // Verifica se il nome del controllo inizia con 'c'
          if (control.name && control.name.startsWith('c')) {
            // Chiamare la funzione di render per ogni controllo
            control.render(
              canvas.contextTop,
              control.left,
              control.top,
              null,
              activeObject
            )
          }
        }
      }

      // Ridisegna il canvas per riflettere le modifiche
      this.canvas.requestRenderAll()
    }
  }

  calculateDimensions() {
    if (this.drawType == 'area') {
      this.calculatePolygonAreas()
    }
    if (this.drawType == 'line') {
      this.calculatePolylineLengths()
    }
  }
  resetView() {
    this.canvas.setZoom(1)
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
    this.drawGrid()
  }

  handleMouseUp() {
    this.canvas.setViewportTransform(this.canvas.viewportTransform)
    this.isDragging = false
    this.canvas.selection = true
    this.calculateDimensions()
  }

  toggleDrawPolygon() {
    console.log('isDrawing', this.isDrawing)
    if (this.isDrawing) {
      // this.stopDrawing(this.pointArray)
      console.log('Stop drawing mode')
      this.activeLine = null
      this.activeShape = null
      this.lineArray = []
      this.pointArray = []
      this.canvas.selection = true
      this.isDrawing = false
      this.editPolygon()
      this.UI.drawBtn.classList.remove('active')
    } else {
      console.log('Start drawing mode')
      this.canvas.selection = false
      this.isDrawing = true
      this.UI.drawBtn.classList.add('active')
    }
  }

  editPolygon() {
    this.canvas.forEachObject((obj) => {
      if (obj.type === 'poly') {
        this.createCustomControl(obj)
        obj.hasBorders = false
      }
    })
    this.canvas.requestRenderAll()
    this.calculateDimensions()
  }
  deletePolygonPoint(obj, index) {
    //Delete the point at the specified index from the polygon
    let maxLength
    if (this.drawType == 'area') {
      maxLength = 3
    }
    if (this.drawType == 'line') {
      maxLength = 2
    }

    if (obj.points.length > maxLength) {
      obj.points.splice(index, 1)
      this.createCustomControl(obj)
    }
  }
  createCustomControl(obj, hasMidControl = true) {
    obj.edit = true
    obj.objectCaching = false
    const lastControl = obj.points.length - 1
    obj.cornerStyle = 'circle'
    obj.controls = obj.points.reduce((acc, point, index, array) => {
      const nextIndex = (index + 1) % obj.points.length // Wrap around for the last point
      const centerX = (point.x + obj.points[nextIndex].x) / 2
      const centerY = (point.y + obj.points[nextIndex].y) / 2
      if (hasMidControl) {
        // Add handle for the center of each line
        acc['c' + index] = new fabric.Control({
          positionHandler: this.lineCenterPositionHandler,
          mouseUpHandler: (eventData, transform, x, y) => {
            console.log('Array length: ' + array.length + ' - INDEX: ' + index)
            if (array.length == 2 && index == 1) {
              this.addMidpoint(obj, 0)
              return true
            }
            if (
              array.length > 2 &&
              index == array.length - 1 &&
              this.drawType == 'line'
            ) {
              return true
            }
            this.addMidpoint(obj, index) // Call your function addMidpoint() here
            return true
          },
          pointIndex: index,
          render: (ctx, left, top, styleOverride, obj) => {
            if (
              array.length > 2 &&
              index == array.length - 1 &&
              this.drawType == 'line'
            ) {
              return true
            }
            const viewportTransform = this.canvas.viewportTransform
            const zoom = this.canvas.getZoom()
            // Calcola le coordinate del mouse rispetto al canvas
            const mouseCanvasX = this.curPointer.x
            const mouseCanvasY = this.curPointer.y

            const controlCanvasX = (left - viewportTransform[4]) / zoom
            const controlCanvasY = (top - viewportTransform[5]) / zoom

            // Calcola la distanza utilizzando le coordinate del control rispetto al canvas
            const distance = this.calculateDistance(
              controlCanvasX,
              controlCanvasY,
              mouseCanvasX,
              mouseCanvasY,
              zoom,
              viewportTransform
            )

            // Calcola l'opacità  basata sulla distanza (puoi personalizzare questa logica a tuo piacimento)
            const minDistance = 30 / zoom // Distanza minima per l'opacità  massima
            const maxDistance = 40 / zoom // Distanza massima per l'opacità  minima

            // Assicurati che distance sia compresa tra minDistance e maxDistance
            const clampedDistance = Math.min(Math.max(distance, minDistance), maxDistance)

            // Normalizza la distanza tra 0 e 1
            const normalizedDistance = (clampedDistance - minDistance) / (maxDistance - minDistance)

            // Calcola l'opacità  inversamente proporzionale alla distanza normalizzata
            const opacity = 1 - normalizedDistance

            // Disegna il cerchio blu
            ctx.beginPath()
            ctx.arc(left, top, 6, 0, 2 * Math.PI, false)
            ctx.fillStyle = `rgba(0, 0, 255, ${opacity})`
            ctx.fill()

            // Disegna il simbolo '+' al centro del cerchio
            const symbolSize = 6 // Dimensione del simbolo
            ctx.fillStyle = `rgba(255, 255, 255, ${opacity})` // Colore del simbolo
            ctx.fillRect(left - 1, top - symbolSize / 2, 2, symbolSize) // Disegna una linea verticale
            ctx.fillRect(left - symbolSize / 2, top - 1, symbolSize, 2) // Disegna una linea orizzontale
          },
        })
      }

      // Add handle for each poly vertex
      acc['p' + index] = new fabric.Control({
        positionHandler: this.polygonPositionHandler,
        actionHandler: this.anchorWrapper(
          index > 0 ? index - 1 : lastControl,
          this.actionHandler
        ),
        mouseUpHandler: (eventData, transform, x, y) => {
          if (eventData.altKey) {
            this.deletePolygonPoint(obj, index) // Call your function addMidpoint() here
          }
          return true
        },
        actionName: 'modifyPolygon',
        pointIndex: index,
      })

      return acc
    }, {})
    this.canvas.renderAll()
  }
  calculateDistance(x1, y1, x2, y2, zoom, viewportTransform) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
  }
  addMidpoint(fabricObject, index) {
    const nextIndex = (index + 1) % fabricObject.points.length
    const newPoint = {
      x: (fabricObject.points[index].x + fabricObject.points[nextIndex].x) / 2,
      y: (fabricObject.points[index].y + fabricObject.points[nextIndex].y) / 2,
    }
    fabricObject.points.splice(nextIndex, 0, newPoint)
    fabricObject.setCoords()
    this.editPolygon()
    this.createCustomControl(this.canvas.getActiveObject())
  }

  // Add a new positionHandler for the center of each line
  lineCenterPositionHandler(dim, finalMatrix, fabricObject, currentControl) {
    console.log(currentControl)
    if (!fabricObject.canvas) { return } // Weird bug where this is null and crashes
    const firstPoint = fabricObject.points[this.pointIndex]
    const nextIndex = (this.pointIndex + 1) % fabricObject.points.length
    const secondPoint = fabricObject.points[nextIndex]
    return fabric.util.transformPoint(
      { x: (firstPoint.x + secondPoint.x) / 2 - fabricObject.pathOffset.x,
        y: (firstPoint.y + secondPoint.y) / 2 - fabricObject.pathOffset.y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    )
  }

  polygonPositionHandler(dim, finalMatrix, fabricObject) {
    return fabric.util.transformPoint(
      { x: fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
        y: fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y,
      },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    )
  }

  actionHandler(eventData, transform, x, y) {
    const polygon = transform.target
    const currentControl = polygon.controls[polygon.__corner]
    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center')
    const polygonBaseSize = polygon._getNonTransformedDimensions()
    const size = polygon._getTransformedDimensions(0, 0)
    const finalPointPosition = {
      x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
      y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
    }
    polygon.points[currentControl.pointIndex] = finalPointPosition
    return true
  }

  anchorWrapper(anchorIndex, fn) {
    return (eventData, transform, x, y) => {
      const fabricObject = transform.target,
        absolutePoint = fabric.util.transformPoint(
          {
            x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
            y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
          },
          fabricObject.calcTransformMatrix()
        ),
        actionPerformed = fn(eventData, transform, x, y),
        newDim = fabricObject._setPositionDimensions({}),
        polygonBaseSize = fabricObject._getNonTransformedDimensions(),
        newX =
          (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
          polygonBaseSize.x,
        newY =
          (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
          polygonBaseSize.y
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)

      if (fabricObject.type === 'referenceLine') {
        this.options.onUpdateReferencePoints(this.referenceLine.points)
      }

      return actionPerformed
    }
  }

  // subtract() {
  //   // IN SVILUPPO:
  //   // seleziona un poligono che verrà  eliminato da tutti gli altri
  //   // (Sarebbe meglio selezionare due soli ed eliminare il secondo al primo selezionato)
  //   const selectedPolyObject = this.canvas.getActiveObject()
  //   const allUnselectedPolyObjects = this.canvas.getObjects().filter((object) => {
  //     return object.type === 'poly' && object !== selectedPolyObject
  //   })
  //   const polys = { regions: [] }

  //   allUnselectedPolyObjects.forEach(function (obj) {
  //     let tmp = obj.points.map(({ x, y }) => [x, y])
  //     polys.regions.push(tmp)
  //   })

  //   //----------------
  //   const polyToSubtract = {
  //     regions: [selectedPolyObject.points.map(({ x, y }) => [x, y])], // list of regions. Each region is a list of points
  //     inverted: false, // is this polygon inverted?
  //   }
  //   const result1 = PolyBool.difference(polys, polyToSubtract)
  //   console.log(result1)
  //   // Sostituisci i poligoni originali con il risultato dell'operazione booleana
  //   this.canvas.remove(
  //     this.canvas.getActiveObjects()[0],
  //     this.canvas.getActiveObjects()[1]
  //   )
  //   const points = result1.regions[0].map((point) => ({
  //     x: point[0],
  //     y: point[1],
  //   }))
  //   const polygon = new fabric.Polygon(points, {
  //     stroke: '#ffffff',
  //     strokeWidth: 1,
  //     fill: '#ccf',
  //   })
  //   this.canvas.add(polygon)
  //   this.canvas.renderAll()
  // }

  calculatePolylineLengths() {
    let total = 0
    let totalSelected = 0
    const originalLength = Math.round(this.getReferenceLength())
    const selectedObject = this.canvas.getActiveObject()
    this.canvas.forEachObject((obj) => {
      if (obj.type === 'polyline') {
        let chunk = this.calculatePolylineLength(obj.points, originalLength)
        total += chunk
      }
    })
    if (selectedObject && selectedObject.type === 'polyline') {
      totalSelected = this.calculatePolylineLength(selectedObject.points, originalLength)
    }
    this.options.onTotalChange({ total, totalSelected })
  }

  calculatePolylineLength(points, originalLength) {
    let lineLength = 0
    for (let i = 0; i < points.length - 1; i++) {
      const point1 = points[i]
      const point2 = points[i + 1]
      lineLength += Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      )
    }
    const scaledLength = Math.round(lineLength) * (this.options.blueprint.reference_value / originalLength)
    return scaledLength
  }

  calculatePolygonAreas() {
    let total = 0
    let totalSelected = 0
    const originalLength = this.getReferenceLength()
    const selectedObject = this.canvas.getActiveObject()
    this.canvas.forEachObject((obj) => {
      if (obj.type === 'poly') {
        total += this.calculatePolygonArea(obj.points, originalLength)
      }
    })
    if (selectedObject && (selectedObject.type === 'poly' || selectedObject.type === 'polyline')) {
      totalSelected = this.calculatePolygonArea(selectedObject.points, originalLength)
    }
    this.options.onTotalChange({ total, totalSelected })
  }

  calculatePolygonArea(points, originalLength) {
    if (!points) {
      if (this.canvas.getActiveObject()) {
        points = this.canvas.getActiveObject().points;
      } else {
        points = this.pointArray.map((point) => ({
          x: point.left,
          y: point.top,
        }));
      }
    }
    const total = points.reduce((acc, current, i, arr) => {
      const next = arr[(i + 1) % arr.length]
      return acc + (current.x * next.y - next.x * current.y)
    }, 0)
    const area = Math.abs(total / 2)
    // We divide by 10000 to convert cm to meters
    return area * Math.pow(this.options.blueprint.reference_value / originalLength, 2) / 10000
  }

  undoLastPoint() {
    if (this.pointArray.length > 0) {
      // Remove the last point and line from the canvas
      this.canvas.remove(this.pointArray.pop())
      this.canvas.remove(this.lineArray.pop())
      this.canvas.remove(this.lineArray.pop())

      if (this.activeShape) {
        const points = this.activeShape.get('points')
        points.pop()
        if (points.length > 0) {
          this.activeShape.set({ points })
        } else {
          this.canvas.remove(this.activeShape)
          this.activeShape = null
        }
        this.canvas.renderAll()
      }
    }
  }

  async loadBackgroundImage(imageFile) {
    return new Promise((resolve, reject) => {
      if (!imageFile) {
        reject(new Error('No file selected'))
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        const imageUrl = e.target.result
        fabric.Image.fromURL(
          imageUrl,
          (img) => {
            this.canvas.setBackgroundImage(
              img,
              this.canvas.renderAll.bind(this.canvas)
            )
            this.canvas.backgroundImage.imageSmoothing = false
            resolve(img)
          },
          null,
          { crossOrigin: 'anonymous' }
        )
      }
      reader.readAsDataURL(imageFile)
    })
  }

  async loadPdf(pdf) {
    var me = this
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 2 })
    const canvasWrapper = document.createElement('div')
    const canvasElement = document.createElement('canvas')
    canvasWrapper.appendChild(canvasElement)
    canvasWrapper.style.position = 'absolute'
    canvasWrapper.style.left = '0'
    canvasWrapper.style.top = '0'
    this.canvas.setWidth(viewport.width)
    this.canvas.setHeight(viewport.height)
    canvasElement.width = viewport.width
    canvasElement.height = viewport.height
    canvasWrapper.style.width = canvasElement.width + 'px'
    canvasWrapper.style.height = canvasElement.height + 'px'
    this.canvas.wrapperEl.appendChild(canvasWrapper)

    await page.render({
      canvasContext: canvasElement.getContext('2d'),
      viewport: viewport,
    }).promise

    this.canvas.setBackgroundImage(canvasElement.toDataURL(), function () {
      me.canvas.renderAll.bind(me.canvas)
      me.canvas.backgroundImage.imageSmoothing = false
    })
    me.canvas.renderAll()
    this.canvas.wrapperEl.removeChild(canvasWrapper)
  }

  loadBackgroundPDF(selectedFile) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = PDF_JS_LIB
    const pdfurl = URL.createObjectURL(selectedFile)
    const loadingTask = pdfjsLib.getDocument(pdfurl)
    loadingTask.promise.then((pdf) => this.loadPdf(pdf))
  }
  exportData() {
    //  let canvasData = JSON.stringify(this.canvas.toJSON())
    let canvasData = JSON.stringify(
      this.canvas.toJSON((obj) => {
        if (obj.type === 'image' && obj.isBackground) {
          return {
            type: 'image',
            path: obj.src,
            isBackground: true, // Make sure obj.isBackground
          }
        }
      })
    )
    return canvasData
  }
}
