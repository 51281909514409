import { ApiService } from '../apiService'

export const getSiteTaskListForCalendar = (projectId, siteId, defaultDate) =>
  ApiService.get(`/projects/${projectId}/sites/${siteId}/sites/calendar`)
    .then(({ dates }) => dates.map((date) => {
      return {
        text: date.title,
        start_date: date.start_date,
        end_date: date.end_date,
        color: date.type === 'site_task' ? '#d25300' : '#508fca',
      }
    }))
