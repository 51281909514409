import { ApiService } from '../apiService'

const findMinDate = (site_tasks) => site_tasks.map(site_task => site_task.start_date).filter(e => e).sort().at(0)

const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
}

const serializeTaskCategories = (task_categories, site_tasks, minDate) =>
  task_categories.map(task_category => ({
    id: task_category.id,
    text: task_category.title,
    start_date: new Date(minDate),
    parent: '0',
    open: true,
    readonly: true,
    task_class: 'cmp-root-task',
  })).filter(category => site_tasks.some(site_task => site_task.task_category_id === category.id))

const serializeSiteTasks = (site_tasks, minDate) => site_tasks.map(site_task => ({
  id: site_task.id,
  text: site_task.title,
  start_date: new Date(site_task.start_date || minDate),
  end_date: new Date(site_task.end_date || addHours(new Date(minDate), 12)),
  estimated_days: site_task.estimated_days,
  duration: 1,
  parent: site_task.task_category_id,
  progress: site_task.percentage_completed / 100.0,
  cellClass: !!site_task.start_date ? 'cmp-saved-task' : 'cmp-unsaved-task',
}))

export const getSiteTaskListForDHTMLXGantt = (projectId, siteId, defaultDate) =>
  ApiService.get(`/projects/${projectId}/sites/${siteId}/sites/gantt`)
    .then(res => {
      const minDate = findMinDate(res.site_tasks) || defaultDate
      return {
        data: [
          ...serializeTaskCategories(res.task_categories, res.site_tasks, minDate),
          ...serializeSiteTasks(res.site_tasks, minDate),
        ],
      }
    })

export const updateGanttTask = (projectId, siteId, task) =>
  ApiService.put(`/projects/${projectId}/sites/${siteId}/sites/gantt?id=${task.id}`, {
    site_task: {
      start_date: task.start_date,
      percentage_completed: task.progress * 100.0,
      end_date: task.end_date,
    },
  }).then(({ site_task }) => ({
    id: site_task.id,
    text: site_task.estimate_description,
    start_date: site_task.start_date,
    end_date: site_task.end_date,
    progress: site_task.percentage_completed,
  }))
