import { Controller } from '@hotwired/stimulus'

const convertToCode = value => value.replace(/[^\w]+/g, '_').replace(/^_+|_+$/g, '')

export default class extends Controller {
  static targets = ['codeInput']

  setCode(e) {
    this.codeInputTarget.value = convertToCode(e.target.value)
  }
}
