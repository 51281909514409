import { Controller } from '@hotwired/stimulus'
import { Tab } from 'bootstrap'
import TomSelect from 'tom-select'
import i18next from 'i18next'

import { getExportTemplates } from '../../../services/organizationsService'
import { ApiService } from '../../../services/apiService'

// Connects to data-controller="sites--exports--form"
export default class extends Controller {
  static targets = [
    'tabPreview',
    'previewer',
    'showPrices',
    'isEstimate',
    'selectSupplier',
    'selectBranding',
    'exportTemplateId',
    'mainText',
    'boxText',
    'sideText',
  ];
  static values = {
    organizationId: String,
    projectId: String,
    siteId: String,
  }

  connect() {
    this.initSupplierSelect()
    this.initBrandingSelect()
    this.initExportTemplateIdSelect()
    this.showPricesChanged()
    this.loadOrganizationTemplates(this.organizationIdValue)
  }

  showPricesChanged() {
    this.isEstimateTarget.checked = this.isEstimateTarget.checked && this.showPricesTarget.checked
    this.isEstimateTarget.disabled = !this.showPricesTarget.checked
    this.exportRequest()
  }

  initSupplierSelect() {
    this.selectSupplierSelect = new TomSelect(
      this.selectSupplierTarget,
      {
        plugins: ['remove_button'],
        selectOnTab: true,
        valueField: 'id',
        labelField: 'name',
        closeAfterSelect: true,
      },
    )
  }

  initBrandingSelect() {
    this.selectBrandingSelect = new TomSelect(
      this.selectBrandingTarget,
      {
        plugins: ['remove_button'],
        placeholder: i18next.t('attributes.organization.title_placeholder'),
        selectOnTab: true,
        valueField: 'id',
        labelField: 'name',
        onItemRemove: () => {
          this.exportTemplateIdSelect.clearOptions()
          this.exportTemplateIdSelect.clear()
          this.exportTemplateIdSelect.disable()
        },
        onItemAdd: (organizationId) => {
          this.loadOrganizationTemplates(organizationId)
        },
      },
    )
  }

  async loadOrganizationTemplates(organizationId) {
    this.exportTemplateIdSelect.clearOptions()
    const exportTemplates = (await getExportTemplates(organizationId)).data;
    if (exportTemplates.length > 0) {
      this.exportTemplateIdSelect.enable()
      this.exportTemplateIdSelect.addOptions(exportTemplates)
      this.exportTemplateIdSelect.refreshOptions(true)
    }
  }

  initExportTemplateIdSelect() {
    this.exportTemplateIdSelect = new TomSelect(this.exportTemplateIdTarget, {
      valueField: 'id',
      labelField: 'name',
      placeholder: i18next.t('views.export_templates.select_template'),
    })
  }

  clearPreviewer() {
    new Tab(this.tabPreviewTarget).show();
    this.element.disabled = true;
    this.previewerTarget.srcdoc = ''
  }

  enablePreviewer(html) {
    this.element.disabled = false;
    this.previewerTarget.srcdoc = html
  }

  async exportRequest() {
    this.clearPreviewer()
    const response = await ApiService.post(`/projects/${this.projectIdValue}/sites/${this.siteIdValue}/sites/exports`, {
      export: {
        format: 'preview',
        show_prices: this.showPricesTarget.checked,
        is_estimate: this.isEstimateTarget.checked,
        supplier_id: this.selectSupplierSelect.getValue(),
        branding_id: this.selectBrandingSelect.getValue(),
        export_template_id: this.exportTemplateIdSelect.getValue(),
        main_text: this.mainTextTarget.value,
        box_text: this.boxTextTarget.value,
        side_text: this.sideTextTarget.value,
      },
    })
    this.enablePreviewer(await response.text())
  }
}
