import { Controller } from '@hotwired/stimulus'
import * as OBC from 'openbim-components'

// Connects to data-controller="components--ifc-viewer"
export default class extends Controller {
  static targets = ['viewer', 'inputFile']

  async connect() {
    const viewerContainer = this.viewerTarget
    const components = new OBC.Components()
    components.scene = new OBC.SimpleScene(components)
    components.renderer = new OBC.SimpleRenderer(components, viewerContainer)
    components.camera = new OBC.SimpleCamera(components)
    components.raycaster = new OBC.SimpleRaycaster(components)
    components.init()

    components.camera.controls.setLookAt(10, 10, 10, 0, 0, 0)
    const scene = components.scene.get()
    components.scene.setup()

    new OBC.SimpleGrid(components)

    const mainToolbar = new OBC.Toolbar(components)
    mainToolbar.name = 'Main toolbar'
    components.ui.addToolbar(mainToolbar)

    // -------------------
    this.dimensions = new OBC.AreaMeasurement(components)
    this.dimensions.enabled = false
    this.dimensions.snapDistance = 1
    this.resetTools()

    const areaMeasureButton = new OBC.Button(components)
    areaMeasureButton.materialIcon = 'square_foot'
    areaMeasureButton.tooltip = 'Measure Area'
    mainToolbar.addChild(areaMeasureButton)
    areaMeasureButton.onClick.add(() => {
      this.mainTool = 'measure_area'
      this.dimensions.enabled = !this.dimensions.enabled

      if (this.dimensions.enabled) {
        this.dimensions.create()
      } else {
        this.stopMeasuringArea()
      }
    })

    const clearMeasurementsButton = new OBC.Button(components)
    clearMeasurementsButton.materialIcon = 'restart_alt'
    clearMeasurementsButton.tooltip = 'Clear measurements'
    mainToolbar.addChild(clearMeasurementsButton)
    clearMeasurementsButton.onClick.add(() => {
      this.dimensions.deleteAll()
    })

    const undoLastButton = new OBC.Button(components)
    undoLastButton.materialIcon = 'undo'
    undoLastButton.tooltip = 'Remove last measurement'
    mainToolbar.addChild(undoLastButton)
    undoLastButton.onClick.add(() => this.undoLastMeasurement())

    viewerContainer.ondblclick = () => this.doubleClick()
    viewerContainer.oncontextmenu = () => {
      console.log('context menu')
      this.dimensions.endCreation()
    }

    window.onkeydown = (event) => {
      if (event.code === 'Delete' || event.code === 'Backspace') {
        this.undoLastMeasurement()
      }
    }
    // -------------------

    let fragmentIfcLoader = new OBC.FragmentIfcLoader(components)
    fragmentIfcLoader.settings.wasm = {
      path: 'https://unpkg.com/web-ifc@0.0.48/',
      absolute: true
    }
    fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true
    fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true

    this.inputFileTarget.addEventListener('change', (event) => {
      const file = event.target.files[0]
      const reader = new FileReader()
      console.log('file', file)
      reader.addEventListener('load', async (event) => {
        const ifcData = event.target.result
        const buffer = new Uint8Array(ifcData)
        const model = await fragmentIfcLoader.load(buffer, 'example')
        scene.add(model)
      })
      reader.readAsArrayBuffer(file)
    })
  }

  undoLastMeasurement() {
    if (this.dimensions._currentAreaElement !== null) {
      this.dimensions._currentAreaElement.dispose()
      this.dimensions._currentAreaElement = null
      this.dimensions.endCreation()
    } else if (this.dimensions._measurements.length > 0) {
      this.dimensions._measurements.pop().dispose()
    }
  }

  doubleClick() {
    if (this.mainTool === 'measure_area') {
      this.closeMeasuringArea()
    }
  }

  closeMeasuringArea() {
    this.dimensions.endCreation()
  }

  stopMeasuringArea() {
    this.closeMeasuringArea()
    this.resetTools()
  }

  resetTools() {
    this.mainTool = 'select'
    this.dimensions.enabled = false
  }
}
