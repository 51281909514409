import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="components--icon-picker"
export default class extends Controller {
  static targets = ['inputHidden', 'imagePreviewer', 'pasteError', 'destroyField'];

  async pasteImage () {
    try {
      this.clearInput()
      const clipboardItem = (await navigator.clipboard.read())[0]
      const imageType = clipboardItem.types.find(type => type.startsWith('image/'))
      const blob = await clipboardItem.getType(imageType)
      const blobUrl = URL.createObjectURL(blob, { type: imageType })
      this.imagePreviewerTarget.classList.remove('invisible')
      this.imagePreviewerTarget.src = blobUrl

      const reader = new FileReader()
      reader.onload = (event) => { this.inputHiddenTarget.value = event.target.result }
      reader.readAsDataURL(blob)
    } catch (err) {
      console.error(err);
      this.pasteErrorTarget.innerHTML = "Immagine da incollare non valida"
    }
  }

  iconFileChanged(event) {
    const file = event.target.files[0];

    if (!file?.type?.startsWith('image/')) { return }

    this.imagePreviewerTarget.classList.remove('invisible')
    const reader = new FileReader()
    reader.onload = (event) => { this.imagePreviewerTarget.src = event.target.result }
    reader.readAsDataURL(file);
  }

  clearInput() {
    this.pasteErrorTarget.innerHTML = ''
    this.inputHiddenTarget.value = ''
    this.imagePreviewerTarget.classList.add('invisible')
    this.destroyFieldTarget.value = true
  }
}
