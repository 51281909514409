import i18next from 'i18next'
import { rounded } from '../../../utils/numericUtils'

export const useTitle = controller => {
  Object.assign(controller, {
    updateTitle() {
      const quantity = rounded(this.quantityTarget.value || 1)
      const rateValue = this.rateValueTarget.value || 0
      const total = rounded(rateValue * quantity)
      const title = this._calculateTitle()
      const quantityText = quantity > 1 ?
        `<strong> x ${this.quantityTarget.value}</strong>` : ''
      const totalText = `${total}€`
      this.summaryDisplayTarget.innerHTML = `<small class="text-secondary d-block text-start w-100">${title}${quantityText}</small><strong class="mx-2 text-secondary d-block text-end text-nowrap fs-6">${totalText}</strong>`
    },

    _calculateTitle() {
      if (!this.hasTitleTarget) { return '' }

      const defaultTitle = `${i18next.t('helpers.submit.new', { model: i18next.t('models.site_task_variant.one') })}…`
      return this.titleTarget.value.length ? this.titleTarget.value : defaultTitle
    }
  })
}
