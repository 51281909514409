import { Controller } from '@hotwired/stimulus'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';
import scheduler from 'dhtmlx-scheduler';
import {
  getSiteTaskListForCalendar,
} from '../../services/sites/calendarService'

// Connects to data-controller="sites--calendar"
export default class extends Controller {
  static values = {
    readonly: Boolean,
    projectId: String,
    siteId: String,
    locale: String,
  }

  async connect() {
    const defaultDate = new Date()

    scheduler.init('calendar_obj', new Date(), 'week');
    const dates = await getSiteTaskListForCalendar(this.projectIdValue, this.siteIdValue, defaultDate);
    scheduler.parse(dates, 'json');
  }
}
