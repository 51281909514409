import { Controller } from '@hotwired/stimulus'
import { gantt } from 'dhtmlx-gantt'
import i18next from 'i18next'

import {
  getSiteTaskListForDHTMLXGantt,
  updateGanttTask,
} from '../../services/sites/ganttService'
import { addNotification } from '../../helpers/notificationHelper'

// Connects to data-controller="sites--dhtmlx-gantt-show"
export default class extends Controller {
  static values = {
    readonly: Boolean,
    projectId: String,
    siteId: String,
    locale: String,
  }

  connect() {
    const defaultDate = new Date()
    this.setupGantt(defaultDate)
    this.setupUpdateCallbacks()
  }

  async setupGantt(defaultDate) {
    gantt.i18n.setLocale(this.localeValue)
    gantt.config.work_time = true
    gantt.config.xml_date = '%Y-%m-%d %H:%i'
    gantt.config.row_height = 40
    gantt.config.show_links = false
    gantt.config.readonly = this.readonlyValue

    gantt.templates.grid_row_class = function (_start, _end, task){
      return task.parent === '0' ? 'cmp-parent-task' : ''
    }
    gantt.templates.task_class = (_start, _end, task) => task.cellClass

    gantt.config.scales = [
      {unit: 'month', step: 1, format: '%F, %Y'},
      {
        unit: 'week',
        step: 1,
        format: (date) => i18next.t('views.gantt.date.week_n') + gantt.date.getWeek(date),
      },
      {
        unit: 'day',
        step: 1,
        format: '%D %d',
        css: (date) => gantt.isWorkTime({ date: date, unit: 'day'}) ? 'cmp-workday' : 'cmp-weekend',
      },
    ]

    gantt.config.columns = [
      { name: 'text', tree: true, width: '*', resize: true, editable: false },
      { name: 'start_date', align: 'center', resize: true },
      {
        name: 'estimate', label: i18next.t('views.gantt.estimate_duration'), align: 'center', width: 90,
        template: (task) => {
          return [
            task.estimated_days ? `<span class="text-secondary">${task.estimated_days}</span> / ` : '- / ',
            task.duration ? `<span class="fw-bold">${task.duration}</span>` : '-',
          ].join('')
        },
      },
    ]

    gantt.plugins({
      drag_timeline: true,
    })

    gantt.attachEvent('onLightbox', function (_taskId){
      const userSection = gantt.getLightboxSection('description')
      const userSelectors =  userSection.node.querySelectorAll("textarea")
      userSelectors.forEach(el => {
        el.setAttribute('disabled', 'disabled')
      })
    })

    gantt.init('gantt_obj')
    const res = await getSiteTaskListForDHTMLXGantt(this.projectIdValue, this.siteIdValue, defaultDate)
    gantt.parse({
      data: res.data,
    })
  }

  setupUpdateCallbacks() {
    gantt.attachEvent('onAfterTaskUpdate', (_id, task) => {
      updateGanttTask(this.projectIdValue, this.siteIdValue, task).then((_siteTask) => {
        addNotification(
          '<span class="fa fa-cloud-arrow-up me-2"></span>' +
            i18next.t('command_result.saved'), 'bg-success', 'gantt-task-saved')
      })
    })

    gantt.attachEvent('onMouseMove', function (id, e) {
      if (!gantt.getState().drag_id && e.buttons == 1) {
        const left_date = gantt.dateFromPos(gantt.getScrollState().x)
        const right_date = gantt.dateFromPos(gantt.getScrollState().x + gantt.$task.offsetWidth - 1)
        if (left_date && +left_date <= +gantt.config.start_date) {
          gantt.config.start_date = gantt.date.add(gantt.config.start_date, -1, 'day')
          gantt.render()
        }
        if (right_date && +gantt.config.end_date < +gantt.date.add(right_date, 1, 'day')) {
          gantt.config.end_date = gantt.date.add(gantt.config.end_date, 1, 'day')
          gantt.render()
        }
      }
    })
  }
}
