import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="agencies--estimates--variant"
export default class extends Controller {
  static targets = ['destroyRef']

  removeVariant() {
    this.element.classList.add('d-none')
    this.destroyRefTarget.value = 'true'

    if (!document.querySelector('[data-form-variant]:not(.d-none)')) {
      document.getElementById('single_estimate_variants').classList.remove('d-none')
    }
  }
}
