import * as Turbo from '@hotwired/turbo'
import '../application'
import '../config/i18next.js'
import '../controllers'
import '../helpers/notificationHelper'

Turbo.start()

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import '../../assets/stylesheets/application.bootstrap.scss'
