import { Controller } from '@hotwired/stimulus'
import sortable from 'html5sortable/dist/html5sortable.es'
import i18next from 'i18next'
import { ApiService } from '../../services/apiService'
import { addNotification } from '../../helpers/notificationHelper'

// Connects to data-controller="site-tasks--reorder"
export default class extends Controller {
  static values = {
    reorderUrl: String
  }

  connect() {
    this.sortableElements = sortable(this.element, {
      forcePlaceholderSize: true,
      placeholder: '<li class="placeholder"></li>',
      items: ".cmp-reorder-item",
      handle: ".cmp-reorder-handle",
    })[0].addEventListener('sortupdate', this.onSortUpdate.bind(this))
  }

  disconnect() {
    if (this.sortableElements) {
      sortable(this.element, 'destroy')
    }
  }

  onSortUpdate(_event) {
    const ordered_site_task_ids = [...this.element.querySelectorAll('.cmp-reorder-item')].map((el) => el.id.replace(/^site_task_/, ''))

    ApiService.post(this.reorderUrlValue, {
      ordered_site_task_ids,
    }).then(() => {
      addNotification(
        '<span class="fa fa-cloud-arrow-up me-2"></span>' +
          i18next.t('command_result.saved'), 'bg-success', 'site-tasks-reordered')
    })
  }
}
