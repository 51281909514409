import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="rooms--form"
export default class extends Controller {
  static targets = ['selectUser']

  connect() {
    this.selectUserLookup()
  }

  selectUserLookup() {
    this.selectUserSelect = new TomSelect(
      this.selectUserTarget,
      {
        placeholder: 'Seleziona contatti...',
        selectOnTab: true,
        create: false,
      },
    )
  }

  submitForm() {
    Turbo.visit(`${this.element.action}?participant_ids=${this.selectUserSelect.getValue().join(',')}`)
  }
}
