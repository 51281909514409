import { Controller } from '@hotwired/stimulus'
import i18next from 'i18next'

function leavingPage(event) {
  if (!window.confirm(i18next.t('utils.form.leave_page_controller.alert_message'))) {
    console.log('prevented');
    event.preventDefault()
    if (event.type == 'beforeunload') {
      event.returnValue = ''
    }
  }
}

// Connects to data-controller="utils--form--leave-page"
export default class extends Controller {
  connect() {
    this.visitHandler = (event) => leavingPage(event)
    window.addEventListener('beforeunload', this.visitHandler)
    document.addEventListener('turbo:before-visit', this.visitHandler)
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.visitHandler)
    document.removeEventListener('turbo:before-visit', this.visitHandler)
  }
}
