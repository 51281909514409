import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'bootstrap'
import i18next from 'i18next'
import TomSelect from 'tom-select'
import { getActivePriceList } from '../../services/agencies/priceListsService'
import { getTaskEstimate } from '../../services/agencies/taskEstimatesService'

const createNewItem = (element, item) => {
  var newItem = document.createElement('li')
  var anchor = document.createElement('a')
  anchor.classList.add('dropdown-item')
  anchor.href = '#'
  anchor.textContent = item.title
  anchor.dataset.siteTaskVariantId = item.id
  newItem.appendChild(anchor)
  element.appendChild(newItem)
}
const createNewItems = (element, items) => {
  element.innerHTML = ''
  items.forEach(item => createNewItem(element, item))
}

// Connects to data-controller="site-tasks--fill-from-estimate"
export default class extends Controller {
  static targets = ['taskEstimateId', 'selectTaskEstimate', 'selectSupplier', 'addVariantButton', 'menuVariants']
  static values = {
    currentOrganizationId: String,
  }

  connect() {
    this.menuVariantDropdown = new Dropdown(this.menuVariantsTarget)
    this.selectSupplierLookup()
    this.selectTaskEstimateLookup()
    this.listenToAddVariantButton()
    if (this.taskEstimateIdTarget.value) {
      this.selectEstimateId(this.taskEstimateIdTarget.value, { startup: true })
    }
  }

  disconnect() {
    this.selectEstimate.destroy()
    this.menuVariantsTarget.removeEventListener('click', this.siteTaskVariantOnClick)
  }

  selectSupplierLookup() {
    this.selectSupplier = new TomSelect(this.selectSupplierTarget, {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      selectOnTab: true,
      placeholder: i18next.t('suppliers.search_from'),
      create: false,
      highlight: true,
      plugins: {
        remove_button: {
          title: i18next.t('command.remove'),
        },
      },
      persist: false,
      onItemAdd: async (_organizationId) => {
        this.selectEstimate.clearOptions()
        this.selectEstimate.load('')
        this.selectEstimate.open()
      },
      onItemRemove: async () => {
        this.selectEstimate.clearOptions()
        this.selectEstimate.load('')
        this.selectEstimate.open()
      }
    })
  }

  selectTaskEstimateLookup() {
    this.selectEstimate = new TomSelect(this.selectTaskEstimateTarget, {
      openOnFocus: true,
      valueField: 'id',
      labelField: 'category',
      searchField: ['category', 'description'],
      selectOnTab: true,
      placeholder: i18next.t('task_estimates.search_from'),
      preload: true,
      create: false,
      highlight: true,
      onItemAdd: async (estimateId) => {
        this.selectEstimateId(estimateId)
      },
      load: async (search, callback) => {
        const { data } = await getActivePriceList({
          organizationId: this.selectSupplier.getValue() || this.currentOrganizationIdValue,
          search,
          date: new Date().toISOString().split('T')[0], // YYYY-MM-DD, tomorrow could be the project's start date
        })
        callback(data)
      },
      render: {
        option: (data, escape) => {
          const optionContainer = document.createElement('div')
          optionContainer.classList.add('cmp-agency-estimate-dropdown-menu')

          const categorySpan = document.createElement('span')
          categorySpan.classList.add('fw-bold', 'text-secondary')
          categorySpan.textContent = data.category
          optionContainer.appendChild(categorySpan)

          const descriptionSpan = document.createElement('span')
          descriptionSpan.classList.add('fs-7')
          descriptionSpan.innerHTML = `<strong class="ms-2">${escape(data.code || '')}</strong> <div>${data.description}</div>`
          optionContainer.appendChild(descriptionSpan)

          return optionContainer.outerHTML
        }
      }
    })
  }

  listenToAddVariantButton() {
    this.siteTaskVariantOnClick = (event) => {
      event.preventDefault()
      if (event.target.dataset.siteTaskVariantId) {
        this.addSiteTaskVariant(event.target.dataset.siteTaskVariantId)
      }
    }
    this.menuVariantsTarget.addEventListener('click', this.siteTaskVariantOnClick)
  }

  async selectEstimateId(estimateId, { startup = false } = {}) {
    const originalEstimateId = this.taskEstimateIdTarget.value
    this.taskEstimateIdTarget.value = estimateId
    this.menuVariantDropdown.hide()
    this.selectEstimate.setValue(null)

    this.fillSiteTaskVariants([])
    const task_estimate = await getTaskEstimate(estimateId)
    const estimate_variants = task_estimate.estimate_variants
    delete task_estimate.estimate_variants

    this.selectedTaskEstimate = task_estimate

    if (!startup && originalEstimateId != estimateId) {
      this.dispatchEstimateSelection()
      this.dispatchEstimateDataEmpty()
    }
    if (estimate_variants.length > 0) {
      this.fillSiteTaskVariants(estimate_variants)
    }
  }

  fillSiteTaskVariants(siteTaskVariants) {
    createNewItems(this.menuVariantsTarget, siteTaskVariants)
    this.setTaskEstimateAddVariant(siteTaskVariants)
  }

  setTaskEstimateAddVariant(siteTaskVariants) {
    if (siteTaskVariants.length > 0) {
      this.addVariantButtonTarget.disabled = false
      this.addVariantButtonTarget.classList.remove('visually-hidden')
    } else {
      this.addVariantButtonTarget.disabled = true
      this.addVariantButtonTarget.classList.add('visually-hidden')
    }
  }

  dispatchEstimateSelection() {
    const taskEstimate = this.selectedTaskEstimate
    window.dispatchEvent(
      new CustomEvent('selectSiteTaskTemplate', {
        detail: {
          siteTaskTemplate: {
            code: taskEstimate.code,
            description: taskEstimate.description,
            estimated_days: taskEstimate.estimated_days,
            icon_url: taskEstimate.icon_url,
            rate_unit: taskEstimate.rate_unit,
            rate_value: taskEstimate.rate_value,
            vat_tax: taskEstimate.vat_tax,
            // Here taskEstimate.task_category_id is temporarily non autopopulated
            // as we probably need AI to match estimate's category to agency's categories
          },
        },
      })
    )
  }

  dispatchEstimateDataEmpty() {
    window.dispatchEvent(new CustomEvent('selectSiteTaskEstimateDataEmpty'))
  }

  addSiteTaskVariant(siteTaskVariantId) {
    window.dispatchEvent(
      new CustomEvent('selectSiteTaskVariantTemplate', {
        detail: {
          siteTaskVariantId: siteTaskVariantId,
          supplierId: this.selectSupplier.getValue(),
        },
      })
    )
  }
}
