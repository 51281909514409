import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--numeric-stepper"
export default class extends Controller {
  static targets = ['stepperInput']
  static values = {
    step: Number,
    max: Number,
  }

  decrement() {
    this.stepperInputTarget.value = Math.max(0, this.curentValue() - (this.stepValue || 1))
  }

  increment() {
    this.stepperInputTarget.value = this.curentValue() + (this.stepValue || 1)
    if (this.maxValue && this.stepperInputTarget.value > this.maxValue) {
      this.stepperInputTarget.value = this.maxValue
    }
  }

  curentValue() {
    return parseInt(this.stepperInputTarget.value || 0)
  }
}
