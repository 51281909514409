import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="site-tasks--default-qty"
export default class extends Controller {
  static targets = ['rateUnit', 'quantity']

  connect() {
    this.changeRateUnit()
  }

  changeRateUnit() {
    const isPricePerPiece = ['fixed_price', 'number'].includes(this.rateUnitTarget.value)
    if (isPricePerPiece && this.quantityTarget.value <= 0) {
      this.quantityTarget.value = 1
    }
  }
}
