import { debounce } from '../utils/timeUtils'

// If toastId is provided it'll replace the existing notification
export const addNotification = debounce((message, toastClass, toastId = null) => {
  const flashContainer = document.getElementById('flash')
  const flashMessageTemplate = document.getElementById('flash_message_template').innerHTML

  const notification = document.createElement('div')
  notification.innerHTML = flashMessageTemplate

  const toastBlock = notification.querySelector('[data-toast-block]')
  toastBlock.classList.add(toastClass || 'bg-info');

  const toastMessage = notification.querySelector('[data-toast-message]');
  toastMessage.innerHTML = message;
  toastBlock.id = toastId

  const existingNotification = document.getElementById(toastId)
  if (toastId && existingNotification) {
    existingNotification.parentNode.replaceChild(notification, existingNotification)
  } else {
    flashContainer.prepend(...notification.childNodes)
  }
})
