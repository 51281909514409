import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import i18next from 'i18next'
import TomSelect from 'tom-select'
import { fillFormFromModel } from '../../utils/fillForm'

// Connects to data-controller="site-tasks--form"
export default class extends Controller {
  static targets = [
    'siteArea',
    'taskCategory',
    'singleSiteTaskVariantContainer',
    'siteTaskVariantContainer',
    'siteTaskVariantsTurboFrameContainer'
  ]

  static values = {
    projectId: String,
    siteId: String,
    agencyId: String,
    siteTaskId: String,
  }

  connect() {
    this.initSelectSiteTaskListener()
    this.initSelectSiteTaskVariantListener()
    this.initTaskCategorySelect()
    this.initSelectSiteTaskEstimateDataEmptyListener()
  }

  disconnect() {
    this.taskCategorySelect.destroy()
    window.removeEventListener('selectSiteTaskTemplate', this.onSelectSiteTaskEvent)
    window.removeEventListener('selectSiteTaskVariantTemplate', this.onSelectSiteTaskVariantTemplateEvent)
    window.removeEventListener('selectSiteTaskEstimateDataEmpty', this.onSelectSiteTaskEstimateDataEmptyEvent)
  }

  siteTaskVariantRemoved() {
    const variants = document.querySelectorAll('[data-site-tasks--form-target="siteTaskVariantContainer"]:not(.d-none)')
    if (variants.length === 0) {
      document.querySelector('[data-site-tasks--form-target="singleSiteTaskVariantContainer"]')
        .classList.remove('d-none')
    }
  }

  initSelectSiteTaskListener() {
    this.onSelectSiteTaskEvent = (e) => {
      const { siteTaskTemplate } = e.detail

      fillFormFromModel({
        site_task: {
          agency_id: siteTaskTemplate.agency_id,
          code: siteTaskTemplate.code,
          estimate_description: siteTaskTemplate.description,
          estimated_days: siteTaskTemplate.estimated_days,
          rate_unit: siteTaskTemplate.rate_unit,
          rate_value: siteTaskTemplate.rate_value,
          vat_tax: siteTaskTemplate.vat_tax,
        },
      })

      // Currently task_category_id is not autopopulated
      // as we probably need AI to match estimate's category to agency's categories
      // this.taskCategorySelect.setValue(siteTaskTemplate.task_category_id)
    }

    window.addEventListener('selectSiteTaskTemplate', this.onSelectSiteTaskEvent)
  }

  initSelectSiteTaskVariantListener() {
    this.onSelectSiteTaskVariantTemplateEvent = (e) => {
      const { siteTaskVariantId, supplierId } = e.detail

      const params = new URLSearchParams({
        site_task_id: this.siteTaskIdValue,
        estimate_variant_ids: siteTaskVariantId.toString(),
        supplier_id: supplierId,
        project_id: this.projectIdValue,
      })
      get(
        `/sites/${this.siteIdValue}/site_task_variants/add_selected_site_task_variant?${params}`,
        { responseKind: 'turbo-stream' }
      )
    }
    window.addEventListener('selectSiteTaskVariantTemplate', this.onSelectSiteTaskVariantTemplateEvent)
  }

  initSelectSiteTaskEstimateDataEmptyListener() {
    this.onSelectSiteTaskEstimateDataEmptyEvent = () => {
      this.siteTaskVariantsTurboFrameContainerTarget
        .querySelectorAll('[data-remove-variant-button]')
        .forEach((element) => element.click())
      this.singleSiteTaskVariantContainerTarget.classList.remove('d-none')
    }
    window.addEventListener('selectSiteTaskEstimateDataEmpty', this.onSelectSiteTaskEstimateDataEmptyEvent)
  }

  initTaskCategorySelect() {
    this.taskCategorySelect = new TomSelect(
      this.taskCategoryTarget,
      {
        placeholder: i18next.t('attributes.task_category.title_placeholder'),
        selectOnTab: true,
        create: true,
        sortField: {
          direction: 'asc'
        },
        render: {
          option_create: (data, escape) => `<div class="create">
            <div class="ms-2 text-light btn btn-sm btn-primary" style="background: #0d6efd">
              Crea categoria <strong>${escape(data.input)}</strong>...
            </div>
          </div>`,
          no_results: function(_data, _escape) {
            return `<div class="no-results">${i18next.t('attributes.base.no_results')}</div>`
          },
        },
      },
    )
  }

  siteTaskVariantContainerTargetConnected() {
    this.singleSiteTaskVariantContainerTarget.classList.add('d-none')
  }
}
